import { CartNode } from '../../queries/cartQueries';
import { OrderItem } from '../../queries/ordersQueries';
import { CartProduct } from '../../types/cartTypes';
import { ProductPricesType } from '../context/CartContext/CartContext';

export const getProductPrices = (item: OrderItem): ProductPricesType => {
  const hasVariations = item.product.type === 'VARIABLE';

  return {
    sale: hasVariations ? item.variation?.salePrice : item.product.salePrice,
    regular: hasVariations ? item.variation?.regularPrice : item.product.regularPrice,
  };
};

export const getProductPricesCart = (item: CartProduct | CartNode): ProductPricesType => {
  const hasVariations = item.product.node.type === 'VARIABLE';

  return {
    sale: hasVariations ? item.variation?.node?.salePrice : item.product.node.salePrice,
    regular: hasVariations ? item.variation?.node?.regularPrice : item.product.node.regularPrice,
  };
};

export const getStockQuantityCart = (item: CartProduct | CartNode) => {
  const product = item.product;

  if (product.node.type === 'SIMPLE') {
    return product.node.stockQuantity || undefined;
  }

  if (product.node.type === 'VARIABLE') {
    return item?.variation?.node.stockQuantity || product.node.stockQuantity || undefined;
  }
};
