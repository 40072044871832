import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from '../../components/shared/Icon/Icon';
import { Image } from '../../components/shared/Image/Image';
import { Quantity } from '../../components/shared/QuantityInput/Quantity';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { LoadingPlaceholder } from '../../components/shared/LoadingPlaceholder/LoadingPlaceholder';
import { CartNode } from '../../queries/cartQueries';
import { useMenuSidebar } from '../../lib/context/MenuSidebarContext/MenuSidebarContext';
import { logError } from '../../lib/helpers/logError';
import { actions, texts } from '../../lib/translation/strings';
import { useTranslation } from 'react-i18next';
import { ProductPrices } from '../../components/shared/ProductPrices/ProductPrices';
import { getProductPricesCart, getStockQuantityCart } from '../../lib/helpers/getProductPrices';

import './CartSidebar.scss';

export const CartSidebar = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const cartCtx = useCartContext();
  const menuSidebar = useMenuSidebar();
  const [loading, setLoading] = useState('');

  const handleQuantityUpdate = async (value: number, item: CartNode) => {
    try {
      setLoading(item?.product?.node?.id);
      await cartCtx.updateProductQuantity(item.key, value);
    } catch (e) {
      logError(e);
    } finally {
      setLoading('');
    }
  };

  return (
    <div ref={ref} className={cn('CartSidebar', menuSidebar.cart && 'CartSidebar--opened')}>
      {cartCtx.isUpdatingProduct && (
        <div className='CartSidebar--updating'>
          <LoadingPlaceholder text={t(actions.loading)} />
        </div>
      )}

      <HandleLoadingState
        loading={cartCtx.isLoading}
        loadingPlaceholder={
          <div className='CartSidebar-loader'>
            <LoadingPlaceholder />
          </div>
        }
      >
        <div className='CartSidebar-head'>
          <p>{t(texts.cart)}</p>
          <span role='button' onClick={() => menuSidebar.closeSidebar('cart')}>
            <Icon icon='x' />
          </span>
        </div>
        {cartCtx.isEmpty ? (
          <div className='CartSidebar-empty'>
            {t(texts.emptyCart)}
            <span style={{ marginLeft: 10 }}>
              <Icon icon='shopping-bag' />
            </span>
          </div>
        ) : (
          <>
            <div className='CartSidebar-body'>
              <ul className='CartSidebar-list'>
                {cartCtx?.items?.map((item, i) => {
                  const product = item.product;

                  const stockQuantity = getStockQuantityCart(item);
                  const prices = getProductPricesCart(item);

                  return (
                    <li key={`${item?.product?.node?.id}-${i}`} className='CartSidebar-list-item'>
                      <NavLink to={`/product/${product?.node?.slug}`}>
                        <div className='CartSidebar-list-item-image'>
                          <Image
                            src={product?.node?.image?.mediaItemUrl}
                            alt={item.product.node.name}
                          />
                        </div>
                      </NavLink>
                      <div className='CartSidebar-list-item-info'>
                        <NavLink to={`/product/${product?.node?.slug}`}>
                          <h4>{product?.node?.name}</h4>
                        </NavLink>
                        <ProductPrices sale={prices.sale} regular={prices.regular} />

                        <Quantity
                          disabled={cartCtx.isUpdatingProduct}
                          loading={loading === item?.product?.node?.id}
                          disableDecrement={item?.quantity === 1}
                          disableIncrement={item?.quantity === stockQuantity}
                          maxQuantity={stockQuantity}
                          quantity={item.quantity}
                          onChange={(value: number) => handleQuantityUpdate(value, item)}
                        />
                      </div>
                      <button
                        disabled={cartCtx.isUpdatingProduct}
                        onClick={() => cartCtx.updateProductQuantity(item.key, 0)}
                        className='CartSidebar-list-item-remove'
                      >
                        <Icon icon='x' />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='CartSidebar-footer'>
              <NavLink to='/checkout'>
                {t(texts.continueWithOrder)} <Icon icon='arrow-right' />
              </NavLink>
            </div>
          </>
        )}
      </HandleLoadingState>
    </div>
  );
};
