import gql from 'graphql-tag';

export type AppointmentFormResponse = {
  appointmentForm: {
    message: string;
  };
};

export type AppointmentFormInput = {
  full_name: string;
  phone: string;
  phone_code: string;
  appointment_type: string;
  email: string;
  category: string;
  online_appointment_type: string;
};

export const APPOINTMENT_FORM = gql`
  mutation Appointment(
    $full_name: String!
    $phone: String!
    $phone_code: String!
    $appointment_type: String!
    $email: String!
    $category: String
    $online_appointment_type: String
  ) {
    appointmentForm(
      input: {
        appointment_type: $appointment_type
        email: $email
        full_name: $full_name
        phone: $phone
        phone_code: $phone_code
        category: $category
        online_appointment_type: $online_appointment_type
      }
    ) {
      message
    }
  }
`;
