import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import { ProfileAddressFields } from '../../../components/ProfilePages/ProfileAddress/ProfileAddress';
import { formValidation } from '../../translation/strings';

interface UseProfileAddressOptions {
  initialValues: ProfileAddressFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProfileAddressFields>
  ) => Promise<any>;
}

export const useProfileAddressFormik = (opts: UseProfileAddressOptions) => {
  const {t} = useTranslation();

  const ProfileAddressSchema = Yup.object().shape({
    billing: Yup.object().shape({
      firstName: Yup.string().required(t(formValidation.firstName)),
      lastName: Yup.string().required(t(formValidation.lastName)),
      city: Yup.string().required(t(formValidation.city)),
      address1: Yup.string().required(t(formValidation.address)),
      phone: Yup.string().required(t(formValidation.phone)),
    }),
  });
  

  return useFormik({
    initialValues: {
      ...opts.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfileAddressSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileAddressFormik = ReturnType<typeof useProfileAddressFormik>;
