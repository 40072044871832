import React from 'react';
import { FormikValues } from 'formik';

import { useAddressQuery } from '../../../lib/hooks/useAddressQuery';

import { ProfileAddressForm } from './ProfileAddressForm';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';

import './ProfileAddress.scss';
import { LoadingPlaceholder } from '../../shared/LoadingPlaceholder/LoadingPlaceholder';

export interface ProfileAddressFields {
  billing: {
    firstName: string;
    lastName: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
    address1: string;
    phone: string;
  };
  shipping: {
    firstName: string;
    lastName: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
    address1: string;
  };
}

export const ProfileAddress = () => {
  const { loading, error, data, update } = useAddressQuery();

  const handleSubmit = (values: FormikValues) => {
    update.mutation(values);
  };

  return (
    <div className='ProfileAddress'>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <div className='d-flex justify-content-center align-items-center'>
            <LoadingPlaceholder text='Loading...' />
          </div>
        }
      >
        <ApolloErrorGuard error={error}>
          <ProfileAddressForm
            customer={data?.customer}
            loading={update.loading}
            onSubmit={(values) => handleSubmit(values)}
          />
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
