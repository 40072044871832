import React from 'react';

import { useCartContext } from '../../lib/context/CartContext/CartContext';

import { CartItem } from '../../components/CartItem/CartItem';
import { CartNode } from '../../queries/cartQueries';
import { Loader } from '../../components/shared/Loader/Loader';

import './CartList.scss';

interface Props {
  products?: CartNode[];
}

export const CartList = (props: Props) => {
  const cartCtx = useCartContext();

  return (
    <div className='CartList'>
      {cartCtx.isUpdatingProduct && <Loader className='CartList__loader' />}

      {props.products?.map((item) => {
        return <CartItem item={item} key={`${item.key}-cart-listing`} />;
      })}
    </div>
  );
};
