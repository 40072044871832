import React, { useContext } from 'react';
import { Translations } from './LanguageContextProvider';

export interface LanguageContextType {
  loading: boolean;
  error?: string | { [key: string]: string };
  translations?: Translations;
  getLangPrefix: () => string;
}

const defaultLanguageContext: LanguageContextType = {
  loading: false,
  error: undefined,
  translations: undefined,
  getLangPrefix: () => {
    return '';
  },
};

export const LanguageContext = React.createContext<LanguageContextType>(
  defaultLanguageContext
);

export function useLanguageContext() {
  return useContext(LanguageContext);
}
