import React, { useCallback, useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useRouter } from '../../../lib/hooks/useRouter';

import './PriceRangeSlider.scss';

interface PriceRangeSliderProps {
  min: number;
  max: number;
  onChange?: ({ min, max }: { min: number; max: number }) => void;
  onMinChange?: (value: number) => void;
  onMaxChange?: (value: number) => void;
}

export const PriceRangeSlider: React.FC<PriceRangeSliderProps> = ({
  min,
  max,
  onMinChange,
  onMaxChange,
  onChange,
}) => {
  const router = useRouter();
  const query = queryString.parse(router.location.search);
  const [minVal, setMinVal] = useState((query?.minPrice as unknown as number) || min);
  const [maxVal, setMaxVal] = useState((query?.maxPrice as unknown as number) || max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef<HTMLDivElement>(null);
  const initialRenderMin = useRef(true);
  const initialRenderMax = useRef(true);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (initialRenderMin.current) {
      initialRenderMin.current = false;
      return;
    }
    const debouncedOnChange = debounce((minVal) => {
      onMinChange?.(minVal);
      onChange?.({ max: maxVal, min: minVal });
    }, 500);

    debouncedOnChange(minVal);

    return () => {
      debouncedOnChange.cancel();
    };
  }, [minVal]);

  useEffect(() => {
    if (initialRenderMax.current) {
      initialRenderMax.current = false;
      return;
    }

    const debouncedOnChange = debounce((maxVal) => {
      onMaxChange?.(maxVal);
      onChange?.({ max: maxVal, min: minVal });
    }, 500);

    debouncedOnChange(maxVal);

    return () => {
      debouncedOnChange.cancel();
    };
  }, [maxVal]);

  // useEffect(() => {
  //   const query = queryString.parse(router.location.search);
  //   if (query?.minPrice && (query.minPrice as unknown as number) !== minVal) {
  //     setMinVal(Number(query.minPrice));
  //   } else {
  //     initialRenderMin.current = true;
  //     setMinVal(min);
  //   }

  //   if (query?.maxPrice && (query.maxPrice as unknown as number) !== maxVal) {
  //     setMaxVal(Number(query.maxPrice));
  //   } else {
  //     initialRenderMax.current = true;
  //     setMaxVal(max);
  //   }
  // }, [router.location.search]);

  return (
    <div className='PriceRangeSlider'>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        onMouseDown={(event) => event.stopPropagation()}
        className='thumb thumb--left'
        style={{ zIndex: minVal > max - 100 ? '5' : undefined }}
      />
      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        onMouseDown={(event) => event.stopPropagation()}
        className='thumb thumb--right'
      />

      <div className='PriceRangeSlider__slider'>
        <div className='PriceRangeSlider__slider__track' />
        <div ref={range} className='PriceRangeSlider__slider__range' />
        <div className='PriceRangeSlider__slider__left-value'>{minVal} €</div>
        <div className='PriceRangeSlider__slider__right-value'>{maxVal} €</div>
      </div>
    </div>
  );
};
