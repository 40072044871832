import { useMutation } from '@apollo/client';
import * as couponMutations from '../../queries/mutations/coupons';

export const useCouponMutations = () => {
  const [applyCoupon, applyCouponRes] = useMutation<
    couponMutations.ApplyCouponResponse,
    couponMutations.CouponVariables
  >(couponMutations.applyCoupon);

  const [removeCoupon, removeCouponRes] = useMutation<
    couponMutations.RemoveCouponResponse,
    couponMutations.CouponVariables
  >(couponMutations.removeCoupons);

  return {
    applyCoupon,
    applyCouponRes,

    removeCoupon,
    removeCouponRes,
  };
};
