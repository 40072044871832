import React from 'react';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import ContentLoader from 'react-content-loader';
import { ProductItemLoader } from '../../ProductsList/ProductItemLoader';

export const HomeSecondaryCollectionLoader = () => {
  return (
    <div className='HomeSecondaryCollection'>
      <div className='HomeSecondaryCollection__left'>
        <BulkLoader length={2}>
          <ContentLoader
            speed={2}
            width={200}
            height={20}
            viewBox='0 0 200 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            style={{ marginBottom: '10px' }}
          >
            <rect x='0' y='0' rx='0' ry='0' width='200' height='20' />
          </ContentLoader>
        </BulkLoader>
        <ContentLoader
          speed={2}
          width={212}
          height={46}
          viewBox='0 0 212 46'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='212' height='46' />
        </ContentLoader>
      </div>
      <div className='HomeSecondaryCollection__products HomeSecondaryCollection__products--loader'>
        <BulkLoader length={3}>
          <ProductItemLoader noWishlistButton />
        </BulkLoader>
      </div>
    </div>
  );
};
