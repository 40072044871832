import React, { useState, useEffect, Fragment } from 'react';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import {
  GET_SINGLE_PRODUCTS,
  ProductSingleProps,
  ProductSingleAttributeProps,
  ProductSingleVariationProps,
  CustomAttributeOption,
  GET_DELIVERY_AND_RETURNS_TEXT,
  DeliveryAndReturnsTextResponse,
} from '../../queries/archive';
import { actions, errors, fields, messages, texts } from '../../lib/translation/strings';
import { ProductSingleLoader } from './ProductSingleLoader';
import { Quantity } from '../../components/shared/QuantityInput/Quantity';
import { ProductPrices } from '../../components/shared/ProductPrices/ProductPrices';
import { Alert } from '../../components/Notifications/Alert';
import { RelatedProducts } from '../../components/RelatedProducts/RelatedProducts';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { ProductSingleSlider } from '../../components/ProductSingle/ProductSingleSlider/ProductSingleSlider';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { ProductSingleSliderLoader } from '../../components/ProductSingle/ProductSingleSlider/ProductSingleSliderLoader';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { useWindowWidth } from '../../lib/hooks/useWindowWidth';
import { useProductSingleHelpers } from './lib/useProductSingleHelpers';
import { ProductSingleModal } from '../../components/ProductSingle/ProductSingleModal/ProductSingleModal';
import { WishListButton } from '../../components/shared/WishListButton/WishListButton';

import './ProductSingle.scss';
import { CUSTOM_SIZE_ATTRIBUTES } from '../../constants';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { Icon } from '../../components/shared/Icon/Icon';

export enum PRODUCT_MODAL {
  DESCRIPTION = 'description',
  DELIVERY = 'delivery',
  SIZES_GUIDE = 'size-guide',
}

export const ProductSingle = () => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const cartCtx = useCartContext();
  const windowWidth = useWindowWidth();
  const notification = useNotification();
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const {
    validateAddToCartProduct,
    getVariationAttributeValue,
    getFirstVariationWithQuantity,
    getStockQuantity,
    getProductPrices,
    getProductVariationSlug,
  } = useProductSingleHelpers();
  const [productModal, setProductModal] = useState<PRODUCT_MODAL | undefined>();
  const [quantity, setQuantity] = useState(1);
  const [filteredVariations, setfilteredVariations] = useState<Array<ProductSingleVariationProps>>(
    [],
  );
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string | null;
  }>({});
  const [filters, setfilters] = useState<Array<ProductSingleAttributeProps>>([]);
  const [customAttribute, setCustomAttribute] = useState<{ [key: string]: string } | undefined>(
    undefined,
  );

  const products = useQuery(GET_SINGLE_PRODUCTS, {
    variables: {
      slugIn: [slug],
    },
  });

  const deliveryReturns = useQuery<DeliveryAndReturnsTextResponse>(GET_DELIVERY_AND_RETURNS_TEXT);

  const product: ProductSingleProps | undefined =
    products.data && products.data.products.nodes.length > 0
      ? products.data.products.nodes[0]
      : undefined;

  const whatsappLink = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${encodeURIComponent(
    `Hi, I'm interested in this product: \n${product?.name} \n${window.location.href}`,
  )}`;

  useEffect(() => {
    //reset state
    setQuantity(1);
    setfilteredVariations([]);
    setSelectedFilters({});
    setfilters([]);
  }, [slug]);

  useEffect(() => {
    if (windowWidth <= 992 && cartCtx.cartUiState.productAdded) {
      notification.success(t(messages.productAdded));
    }
  }, [cartCtx.cartUiState.productAdded]);

  useEffect(() => {
    authCtx.setIsLoadingData(products.loading);
  }, [products.loading]);

  useEffect(() => {
    if (products.loading || !product?.attributes?.nodes) {
      return;
    }

    const productAttributes = product?.attributes?.nodes?.filter((n) => n.visible) || [];

    const firstVariationWithQuantity = getFirstVariationWithQuantity(product);

    const slFilters: { [key: string]: string | null } = {};
    const attributeFilters: ProductSingleAttributeProps[] = productAttributes
      .filter((attr) => attr.options && attr.options.length >= 1 && attr.name !== 'pa_mosha')
      .map((attr: ProductSingleAttributeProps) => {
        const variationAttribute = firstVariationWithQuantity?.attributes?.nodes.find((node) => {
          return node.name === attr.name.toLowerCase();
        });

        slFilters[attr.name.toLowerCase()] = variationAttribute?.value || null;
        const options = attr.customAttributesOptions
          ? attr.customAttributesOptions.map((customAttr: CustomAttributeOption) => customAttr.name)
          : [];
        return {
          ...attr,
          options,
          // eslint-disable-next-line camelcase
          enabled_options: [...options],
        };
      });
    if (product.type === 'SIMPLE') {
      setfilters(
        productAttributes.filter((attr) => !['pa_occasion', 'pa_wear-type'].includes(attr.name)),
      );
    } else {
      setfilters(
        attributeFilters.filter((attr) => !['pa_occasion', 'pa_wear-type'].includes(attr.name)),
      );
      setSelectedFilters(slFilters);
    }

    if (firstVariationWithQuantity) {
      setfilteredVariations([firstVariationWithQuantity]);
    }
  }, [!products.loading && products.data && slug]);

  // handlers
  const onSelectFilter = (selectedFilterName: string, filterValue: string) => {
    notification.removeAll();
    setQuantity(1);

    if (
      selectedFilters[selectedFilterName] === undefined ||
      selectedFilters[selectedFilterName] === filterValue
    ) {
      return;
    }

    selectedFilters[selectedFilterName] = filterValue;
    setSelectedFilters((prev) => ({ ...prev, [selectedFilterName]: filterValue }));

    const productVariations = product?.variations.nodes || [];

    //filter variations based on selected attributes
    const filteredVariations = productVariations.filter(
      (variation: ProductSingleVariationProps) => {
        const hasDifferentAttributeValue = Object.keys(selectedFilters)
          .filter((key) => selectedFilters[key])
          .find((key) => getVariationAttributeValue(variation, key) !== selectedFilters[key]);

        return !hasDifferentAttributeValue;
      },
    );

    setfilteredVariations(filteredVariations);

    filters.map((filter: ProductSingleAttributeProps) => {
      // set the enabled_options for each filter based on the filteredVariations
      // Don't touch the current filter if it is becoming active - add to if to disable (&& filter.name === selectedFilterName)
      if (selectedFilters[selectedFilterName] !== null) {
        return filter;
      }

      const attributeValues = filteredVariations?.map((variation) =>
        getVariationAttributeValue(
          variation,
          filter.scope === 'LOCAL' ? filter.name.toLowerCase() : filter.name,
        ),
      );

      filter['enabled_options'] = attributeValues?.filter(
        (a, i) => !!a && attributeValues?.indexOf(a) === i,
      );

      return filter;
    });

    setfilters(filters);
  };

  const addToCart = async () => {
    if (!product) return;

    if (!validateAddToCartProduct(product, filteredVariations, quantity, customAttribute)) {
      return;
    }

    try {
      await cartCtx.addProduct(
        product.databaseId,
        quantity,
        filteredVariations[0],
        customAttribute,
      );
      setQuantity(1);
    } catch (e) {
      notification.warning((e as Error).message, true);
    }
  };

  const handleCustomAttributeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAttribute((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const getProductSingleModalContent = () => {
    switch (productModal) {
      case PRODUCT_MODAL.DESCRIPTION:
        return product?.description;
      case PRODUCT_MODAL.DELIVERY:
        return deliveryReturns?.data?.generalOption?.generalOptions?.deliveryReturns;
      case PRODUCT_MODAL.SIZES_GUIDE:
        return deliveryReturns?.data?.generalOption?.generalOptions?.sizeGuide;
      default:
        return '';
    }
  };

  const productStockQuantity = getStockQuantity(filteredVariations, product);
  const productPrices = getProductPrices(filteredVariations, product);
  const isSimpleProduct = product && product.type === 'SIMPLE';

  const gallery = product?.galleryImages?.nodes ? product.galleryImages.nodes : [product?.image];

  const variationImageSlug = getProductVariationSlug(
    product?.variations?.nodes,
    selectedFilters['pa_color'] || undefined,
  );

  return (
    <div
      className={cn(
        'ProductSingle',
        isSimpleProduct ? 'ProductSingle--simple' : 'ProductSingle--variable',
      )}
    >
      <Container>
        <HandleLoadingState
          loading={products.loading || deliveryReturns.loading}
          loadingPlaceholder={
            <Row>
              <Col xs='12' md='7'>
                <ProductSingleSliderLoader />
              </Col>
              <Col xs='12' md='5'>
                <ProductSingleLoader />
              </Col>
            </Row>
          }
        >
          <ApolloErrorGuard error={products.error || deliveryReturns.error}>
            {!product ? (
              <Alert type='warning' message={t(errors.noProductFound)} />
            ) : (
              <>
                <div className='ProductSingle'>
                  <div className='ProductSingle__wrapper'>
                    <ProductSingleSlider
                      selectedVariationSlug={variationImageSlug}
                      featureImage={product?.image?.sourceUrl}
                      alt={product?.name}
                      gallery={gallery}
                      isOutOfStock={productStockQuantity === 0}
                    />
                    <div className='ProductSingle__content'>
                      <div className='ProductSingle__content__header'>
                        <h1 className='ProductSingle__content__title'>{product?.name}</h1>
                      </div>
                      {filters.length > 0 && (
                        <div className='ProductSingle__content__variations_wrapper'>
                          {filters?.map((attribute: ProductSingleAttributeProps) => {
                            return (
                              <div
                                key={attribute.name}
                                className='ProductSingle__content__variations'
                              >
                                {attribute.options ? (
                                  <div className='ProductSingle__content__variations--options'>
                                    <span className='label'>
                                      {t(texts[attribute.name.toLowerCase() as keyof typeof texts])}
                                      {isSimpleProduct && ':'}
                                    </span>
                                    <div className='d-flex align-items-center'>
                                      {attribute.options.map((option: string, index: number) => {
                                        const isColor = attribute.name === 'pa_color';
                                        const isEnabled = attribute['enabled_options']?.includes(option) && product?.type === 'VARIABLE'; // prettier-ignore
                                        const disabledClass = !isEnabled && product?.type === 'VARIABLE' ? ' disabled' : ''; // prettier-ignore
                                        const attrName = attribute.scope === 'LOCAL' ? attribute.name.toLowerCase() : attribute.name; // prettier-ignore
                                        const _thisCustomAttribute = attribute.customAttributesOptions.find((x) => x.name === option); // prettier-ignore
                                        const isActiveClass = selectedFilters[attrName] === option || selectedFilters[attrName] === _thisCustomAttribute?.slug ? ' isActive' : ''; // prettier-ignore

                                        return (
                                          <Fragment key={`${option}-${index}`}>
                                            <span
                                              className={`${ isColor ? 'color' : 'size' }${disabledClass}${isActiveClass}`} // prettier-ignore
                                              onClick={() => {
                                                isEnabled &&
                                                  onSelectFilter(
                                                    attrName,
                                                    option.toLocaleLowerCase().replace('#', ''),
                                                  );
                                              }}
                                            >
                                              {isColor ? (
                                                <div
                                                  style={
                                                    isColor
                                                      ? {
                                                          backgroundColor: `${option}`,
                                                          backgroundImage:
                                                            _thisCustomAttribute?.image
                                                              ? `url(${_thisCustomAttribute?.image})`
                                                              : 'unset',
                                                        }
                                                      : {}
                                                  }
                                                />
                                              ) : (
                                                t(option).toUpperCase()
                                              )}
                                            </span>
                                          </Fragment>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <p className='mb-0'>{attribute.name} has no options error</p>
                                )}
                                {selectedFilters[attribute.name.toLowerCase()] === 'custom' && (
                                  <div className='ProductSingle__content__variations__customOptions'>
                                    {CUSTOM_SIZE_ATTRIBUTES.map((attribute, index) => {
                                      return (
                                        <FormGroup
                                          key={`custom-${attribute}-${index}`}
                                          className='form-group'
                                        >
                                          <Label for={`custom-${attribute}`}>{attribute}</Label>
                                          <Input
                                            id={`custom-${attribute}`}
                                            type='text'
                                            required
                                            name={attribute}
                                            className='form-control'
                                            placeholder='e.g. 10cm'
                                            value={customAttribute?.[attribute] || ''}
                                            onChange={handleCustomAttributeChange}
                                          />
                                        </FormGroup>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className='ProductSingle__content__variations'>
                        <div className='ProductSingle__content__variations--options'>
                          <span className='label mb-0'>{t(fields.price)}</span>
                          <ProductPrices
                            regular={productPrices?.regularPrice}
                            sale={productPrices?.salePrice}
                          />
                        </div>
                      </div>

                      <div className='ProductSingle__content__links'>
                        <p onClick={() => setProductModal(PRODUCT_MODAL.DESCRIPTION)}>
                          {t(fields.description)}
                        </p>
                        <p onClick={() => setProductModal(PRODUCT_MODAL.DELIVERY)}>
                          {t(texts.deliveryReturns)}
                        </p>
                        <p onClick={() => navigate('/size-guide')}>{t(texts.sizeGuide)}</p>
                      </div>

                      {cartCtx.error && <Alert type='warning' message={cartCtx.error.toString()} />}

                      <div className='ProductSingle__content__actions mt-auto'>
                        <Quantity
                          hideMobile={true}
                          disabled={productStockQuantity === 0}
                          quantity={quantity}
                          maxQuantity={productStockQuantity}
                          onChange={(quantity: number) => setQuantity(quantity)}
                        />

                        <button
                          id='addToCardBtn'
                          onClick={addToCart}
                          disabled={cartCtx.isAddingProduct || productStockQuantity === 0}
                          className={`add_to_cart ${ cartCtx.isAddingProduct || productStockQuantity === 0  ? 'disabled' : ''}`} // prettier-ignore
                        >
                          {cartCtx.isAddingProduct && (
                            <Spinner
                              type='border'
                              color='white'
                              style={{
                                width: '1.25rem',
                                height: '1.25rem',
                                borderWidth: '.2em',
                                marginRight: '1.5em',
                              }}
                            />
                          )}
                          {cartCtx.cartUiState.productAdded
                            ? t(messages.singleProductAdded)
                            : t(actions.addToBag)}
                        </button>
                        <div className='ProductSingle__content__actions--extras'>
                          <WishListButton id={product.databaseId} />
                          <a
                            href={whatsappLink}
                            title='Chat with us on WhatsApp'
                            target='_blank'
                            rel='noreferrer'
                            className='Whatsapp__button'
                          >
                            <Icon icon='whatsapp' />
                            WhatsApp
                          </a>
                          {/* <ProductSingleShareButton /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ProductSingleModal
                  type={productModal}
                  description={getProductSingleModalContent()}
                  close={() => setProductModal(undefined)}
                />
                <RelatedProducts
                  excludedID={product.databaseId}
                  category={product.productCategories.nodes[0]?.slug || ''}
                />
              </>
            )}
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
