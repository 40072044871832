import React, { useContext } from 'react';
import { AuthContextUserProps } from './AuthContextProvider';
import {
  RegisterProps,
  ResetPasswordProps
} from '../../../queries/mutations/userMutations';

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoggingIn: boolean;
  isRegistering: boolean;
  user?: AuthContextUserProps;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
  checkAuthentication: () => void;
  login: (username: string, password: string, redirect?: boolean) => void;
  logout: (redirect?: string | false) => void;
  register: (input: RegisterProps, noRedirect?: boolean) => void;
  updateUser: (values: AuthContextUserProps) => void;
  forgotPassword: (username: string) => Promise<any>;
  resetPassword: (input: ResetPasswordProps) => Promise<any>;
  setIsLoadingData: (value: boolean) => void;
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  isLoadingData: false,
  isLoggingIn: false,
  isRegistering: false,
  user: undefined,
  error: undefined,
  loginError: undefined,
  registerError: undefined,
  checkAuthentication: () => {},
  login: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {},
  forgotPassword: () => new Promise((resolve) => resolve),
  resetPassword: () => new Promise((resolve) => resolve),
  setIsLoadingData: () => {},
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
