import React, { useState } from 'react';
import cs from 'classnames';
import { useWishlist } from '../../../lib/hooks/useWishlist';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import './WishListButton.scss';

interface Props {
  id: number;
}

export const WishListButton = (props: Props) => {
  const { addToWishlist, isProductOnWishList, removeItem } = useWishlist();
  const [isFavorite, setIsFavorite] = useState(isProductOnWishList(props.id));

  const handleClick = () => {
    if (isFavorite) {
      removeItem(props.id);
      setIsFavorite(false);
      return;
    }
    addToWishlist(props.id);
    setIsFavorite(true);
  };

  return (
    <Button
      color='secondary'
      className={cs('WishListButton', isFavorite && 'WishListButton--fill')}
      onClick={handleClick}
    >
      <Icon icon={isFavorite ? 'heart-filled' : 'heart-thin'} />
    </Button>
  );
};
