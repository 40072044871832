import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from '../../../components/shared/Icon/Icon';
import { useMenuContext } from '../../../lib/context/MenuContext/MenuContext';
import { useOutsideClickDetection } from '../../../lib/hooks/useOutsideClickDetection';
import { useFetchMenu } from '../../../lib/hooks/useFetchMenu';
import { HandleLoadingState } from '../../../components/shared/HandleLoadingState/HandleLoadingState';
import { ApolloErrorGuard } from '../../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { useRouter } from '../../../lib/hooks/useRouter';
import { splitArray } from '../../../lib/helpers/splitArray';
import { MenuResponsiveLoader } from './MenuResponsiveLoader';
import { ThemeLink } from '../../../components/shared/ThemeLink/ThemeLink';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { useMenuSidebar } from '../../../lib/context/MenuSidebarContext/MenuSidebarContext';

import './MenuResponsive.scss';

export const MenuResponsive = () => {
  const router = useRouter();
  const ref = useRef(null);
  const menuCtx = useMenuContext();
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();
  const [submenu, setSubmenu] = useState<string | undefined>(undefined);
  const { categories, loading, error } = useFetchMenu();

  useOutsideClickDetection(ref, () => closeMenu());

  useEffect(() => {
    closeSubmenu();
  }, [router.location.pathname]);

  const handleMenuItemClick = (slug: string, hasChildren?: boolean) => {
    if (!hasChildren) {
      router.push(`/shop/${slug}`);
      return;
    }
    if (slug === submenu) {
      closeSubmenu();
      return;
    }
    setSubmenu(slug);
  };

  const closeMenu = () => {
    menuCtx.closeSidebar();
    setSubmenu(undefined);
  };
  const closeSubmenu = () => {
    setSubmenu(undefined);
  };

  const [firstTwoElements, restOfElements] = splitArray(
    categories.find(({ slug }) => slug === submenu)?.children?.nodes,
    2,
  );

  return (
    <div ref={ref} className={cn('MenuResponsive', menuCtx.isOpened && 'MenuResponsive__opened')}>
      <div className='MenuResponsive__header'>
        {!!submenu && (
          <div className='MenuResponsive__header__back' onClick={closeSubmenu}>
            <Icon icon='chevron-left' className='chevron' />
            <span>Back</span>
          </div>
        )}
        <span role='button' className='MenuResponsive__close' onClick={closeMenu}>
          <Icon icon='x' />
        </span>
      </div>
      <div className='MenuResponsive__wrapper'>
        {!submenu ? (
          <div className='MenuResponsive__list'>
            {categories?.map(({ id, name, slug, children }) => {
              const hasChildren = children?.nodes?.length > 0;
              return (
                <div
                  key={id}
                  data-root='true'
                  className={cn(
                    'MenuResponsive__list-item',
                    hasChildren && 'MenuResponsive__list-item--has-children',
                  )}
                >
                  <div
                    data-opened={submenu === slug}
                    className='MenuResponsive__list-item-toggle'
                    onClick={() => handleMenuItemClick(slug, hasChildren)}
                  >
                    <span>{name.toLocaleLowerCase()}</span>
                    {hasChildren && <Icon icon='chevron-right' className='chevron' />}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='MenuResponsive__submenu'>
            <HandleLoadingState loading={loading} loadingPlaceholder={<MenuResponsiveLoader />}>
              <ApolloErrorGuard error={error}>
                <div className='MenuResponsive__submenu__wrapper'>
                  <div className='MenuResponsive__submenu__top'>
                    <h6>Collections</h6>
                    <ul className='MenuResponsive__submenu__list'>
                      {restOfElements?.map((category) => (
                        <li key={category.id} className='MenuResponsive__submenu__list-item'>
                          <NavLink
                            to={`/shop/${submenu}/${category.slug}`}
                            className='MenuResponsive__submenu__list__item-title'
                          >
                            {category?.name.toLocaleLowerCase()}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='MenuResponsive__submenu__bottom'>
                    {firstTwoElements?.map((category) => (
                      <NavLink
                        key={category.id}
                        to={`/shop/${submenu}/${category.slug}`}
                        className='MenuResponsive__submenu__box'
                      >
                        {category?.image?.sourceUrl && (
                          <div className='MenuResponsive__submenu__box-image'>
                            <img src={category?.image?.sourceUrl} alt={category?.name} />
                          </div>
                        )}
                        <div className='MenuResponsive__submenu__box-content'>
                          <h5>{category?.name.toLocaleLowerCase()}</h5>
                          <span>Discover more</span>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                </div>
              </ApolloErrorGuard>
            </HandleLoadingState>
          </div>
        )}

        {!submenu && (
          <div className='MenuResponsive__footer'>
            <div className='MenuResponsive__footer__item MenuResponsive__footer__item--login'>
              {authCtx.isAuthenticated ? (
                <ThemeLink to='/my-profile' activeClassName='MenuResponsive__footer__item--active'>
                  <span className='MenuResponsive__footer__item--user-icon'>
                    <Icon icon='user' />
                  </span>
                </ThemeLink>
              ) : (
                <ThemeLink to='/login' activeClassName='MenuResponsive__footer__item--active'>
                  <span className='MenuResponsive__footer__item--user-icon'>
                    <Icon icon='user' />
                  </span>
                </ThemeLink>
              )}
            </div>

            <span
              role='button'
              onClick={() => menuSidebar.openSidebar('wishlist')}
              className='MenuResponsive__footer__item MenuResponsive__footer__item--wishlist'
            >
              <span className='MenuResponsive__footer__item--wishlist-icon'>
                <Icon icon='heart' />
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
