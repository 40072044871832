import { jwtDecode } from 'jwt-decode';

export function isTokenExpired(token: string | null) {
  if (!token) {
    return true;
  }

  try {
    const decoded:any = jwtDecode(token);
    return Date.now() >= decoded.exp * 1000;
  } catch (e) {
    return true;
  }
}
