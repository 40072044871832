import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { InputGroup, Input, Form, Container } from 'reactstrap';
import { useRouter } from '../../../lib/hooks/useRouter';
import { Icon } from '../../../components/shared/Icon/Icon';
import { HandleLoadingState } from '../../../components/shared/HandleLoadingState/HandleLoadingState';
import { actions, errors } from '../../../lib/translation/strings';
import * as searchQueries from '../../../queries/search';
import { ProductItem } from '../../../components/ProductsList/ProductItem';
import { BulkLoader } from '../../../components/shared/BulkLoader/BulkLoader';
import { ProductItemLoader } from '../../../components/ProductsList/ProductItemLoader';
import { ApolloErrorGuard } from '../../../components/shared/ApolloErrorGuard/ApolloErrorGuard';

import './Search.scss';
import { HandleNoItemsState } from '../../../components/shared/HandleNoItemsState/HandleNoItemsState';
import { ThemeLink } from '../../../components/shared/ThemeLink/ThemeLink';

interface Props {
  className?: string;
  onClose?: () => void;
}
export const Search = ({ className, onClose }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [search, { data, loading, error }] = useLazyQuery<
    searchQueries.SearchAutocompleteProductsResponse,
    searchQueries.SearchProductVariables
  >(searchQueries.searchAutocompleteProducts);

  useEffect(() => {
    setOpen(false);
  }, [router.location]);

  const searchProducts = async (value: string) => {
    setOpen(true);
    search({
      variables: {
        limit: 4,
        term: value,
        after: '',
      },
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue.length < 3) {
      return;
    }

    router.push(`/search?s=${searchValue}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.length === 0) {
      setOpen(false);
      return;
    }

    if (value.length > 2) {
      searchProducts(value);
    }
  };

  return (
    <div className={classNames('Search', className, open && 'Search--has-results')}>
      <span role='button' className='Search__close' onClick={() => onClose?.()}>
        <Icon icon='x' />
      </span>

      <Container>
        <Form className='Search__form' onSubmit={handleSubmit}>
          <InputGroup>
            <Input
              value={searchValue}
              className='Search__input'
              onChange={handleSearchChange}
              placeholder={t(actions.findProduct)}
            />

            <button type='submit' className='Search__btn_submit'>
              <Icon icon='search' style={{ margin: '0 auto' }} />
            </button>
          </InputGroup>
        </Form>

        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <div className='Search__results'>
              <BulkLoader length={4}>
                <ProductItemLoader noWishlistButton />
              </BulkLoader>
            </div>
          }
        >
          <ApolloErrorGuard error={error}>
            <HandleNoItemsState
              hasItems={!!data?.products}
              placeholder={
                <div className='Search__trendings'>
                  <h4>Trending search</h4>
                  <ThemeLink to='/search?s=bridal'>
                    <Icon icon='search' /> Bridal
                  </ThemeLink>
                  <ThemeLink to='/search?s=dresses'>
                    <Icon icon='search' />
                    Dresses
                  </ThemeLink>
                  <ThemeLink to='/search?s=accessories'>
                    <Icon icon='search' />
                    Accessories
                  </ThemeLink>
                </div>
              }
            >
              <HandleNoItemsState
                hasItems={!!data?.products?.nodes?.length && data?.products?.nodes?.length > 0}
                placeholder={
                  <div className='Search__results__more'>
                    <p className='text-center'>{t(errors.noProductsFound)}</p>
                  </div>
                }
              >
                <div className='Search__results'>
                  {data?.products?.nodes?.map((product) => (
                    <ProductItem key={product.id} product={product} noWishlistButton />
                  ))}
                </div>
                {data?.products?.pageInfo?.hasNextPage && (
                  <div className='Search__results__more'>
                    <Link to={`/search?s=${searchValue}`}>{t(actions.seeAllResults)}</Link>
                  </div>
                )}
              </HandleNoItemsState>
            </HandleNoItemsState>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
