import React from 'react';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { useQuery } from '@apollo/client';
import { HomeProcessesResponse, getHomeProcesses } from '../../../queries/home';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import { HomeProcessesLoader } from './HomeProcessesLoader';

import './HomeProcesses.scss';

export const HomeProcesses = () => {
  const { loading, data, error } = useQuery<HomeProcessesResponse>(getHomeProcesses);

  const processes = data?.generalOption.homeSettings.processes.list;

  return (
    <div className='HomeProcesses'>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <BulkLoader length={3}>
            <HomeProcessesLoader />
          </BulkLoader>
        }
      >
        <ApolloErrorGuard error={error}>
          {processes?.map((item, index) => {
            return (
              <div key={index} className='HomeProcesses__process'>
                {item.icon?.node?.sourceUrl && (
                  <div className='HomeProcesses__process-img'>
                    <img src={item.icon.node.sourceUrl} alt={item.title} />
                  </div>
                )}
                <div className='HomeProcesses__process-content'>
                  <h6>{item.title}</h6>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
