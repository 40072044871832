import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { formInputs, formValidation, texts } from '../../lib/translation/strings';

import { ADD_EMAIL_TO_KLAVIYO, KlaviyoResponse } from '../../queries/mutations/contactMutation';

import './NewsLetterForm.scss';
import { Icon } from '../shared/Icon/Icon';

interface FormValues {
  email: string;
}

export const NewsLetterForm = () => {
  const { t } = useTranslation();

  const [addEmailToKlaviyo] = useMutation<KlaviyoResponse>(ADD_EMAIL_TO_KLAVIYO);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(t(formValidation.email)),
  });

  const sendEmailToKlaviyo = async (values: FormValues) => {
    try {
      const response = await addEmailToKlaviyo({ variables: { email: values.email } });

      if (response?.data?.addEmailToKlaviyo.success) {
        setIsSuccess(true);

        setTimeout(() => setIsSuccess(false), 5000);
      } else {
        setIsSuccess(false);
        console.log('faild');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='NewsLetterForm'>
      <p className='text'>{t(texts.joinOurCommunity)}</p>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          sendEmailToKlaviyo(values).finally(() => {
            setSubmitting(false);
            resetForm();
          });
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup className='form-group'>
              <Input
                id='email'
                type='email'
                name='email'
                placeholder={t(formInputs.email)}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control-custom'
                autoComplete='off'
                invalid={!!formik.errors.email && formik.touched.email}
              />
              {formik.touched.email && <FormFeedback>{formik.errors.email}</FormFeedback>}

              <div onClick={() => formik.handleSubmit()} className='submit-icon'>
                <Icon icon='arrow-right' />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>

      {isSuccess && <span className='text-white'>{t(texts.subscribed)}</span>}
    </div>
  );
};
