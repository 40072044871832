import gql from 'graphql-tag';

export interface GetMainMenuVariables {
  location: string;
}

export interface GetMainMenuResponse {
  menus: {
    nodes: Array<Menu>;
  };
}

export interface Menu {
  id: string;
  menuItems: {
    nodes: MenuItem[];
  };
}

export type MenuLevelType = 'inline' | 'column';

export interface MenuItem {
  id: string;
  url: string;
  label: string;
  childItems?: {
    nodes: MenuItem[];
  };
}

export const GET_MAIN_MENU = gql`
  query getMainMenu($location: MenuLocationEnum) {
    menus(where: { location: $location }) {
      nodes {
        id
        menuItems {
          nodes {
            id
            url
            label
            childItems {
              nodes {
                id
                url
                label
                childItems {
                  nodes {
                    id
                    url
                    label
                    childItems {
                      nodes {
                        id
                        url
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type GetSubCategoriesBySlugResponse = {
  productCategories: {
    nodes: {
      id: string;
      name: string;
      slug: string;
      children: {
        nodes: {
          id: string;
          name: string;
          slug: string;
          image: {
            id: string;
            sourceUrl: string;
          };
        }[];
      };
    }[];
  };
};

export const GET_CATEGORIES_MENU = gql`
  query getCategoriesMenu {
    productCategories(
      first: 10
      where: { order: ASC, orderby: TERM_ORDER, hierarchical: true, parent: 0, includeInMenu: true }
    ) {
      nodes {
        id
        name
        slug
        children(first: 10, where: { order: ASC, orderby: TERM_ORDER, includeInMenu: true }) {
          nodes {
            id
            name
            slug
            image {
              id
              sourceUrl(size: LARGE)
            }
          }
        }
      }
    }
  }
`;
