import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Coupon } from '../../../types/generalTypes';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { CartNode } from '../../../queries/cartQueries';
import { ProductSingleVariationProps } from '../../../queries/archive';
import { ApolloError } from '@apollo/client';
import { ApolloErrorOptions } from '@apollo/client/errors';

export interface CartUiState {
  open: boolean;
  productAdded: boolean;
}

export interface CartContextType {
  // Loading states
  isLoading: boolean;
  isUpdatingProduct: boolean;
  isUpdatingShippingMethod: boolean;
  isRemovingCoupon: boolean;
  isApplyingCoupon: boolean;
  isAddingProduct: boolean;

  // Cart details
  isEmpty: boolean | undefined;
  hasCoupons: boolean;
  total: string;
  subtotal: string;
  shippingTotal: string;
  chosenShippingMethods: string;
  itemCount: number | null;
  appliedCoupons: Coupon | null;
  items: CartNode[] | undefined;

  // other
  error?: ApolloError | ApolloErrorOptions;
  cartUiState: CartUiState;

  // methods
  refetch(variables?: any): Promise<ApolloQueryResult<any>>;
  addProduct: (
    productID: number,
    quantity: number,
    variation: ProductSingleVariationProps | undefined,
    extraData?: { [key: string]: string },
  ) => void;
  updateShippingMethod: (ids: string[]) => void;
  updateProductQuantity: (key: string, value: number) => void;
  applyCoupon: (ids: string) => Promise<any>;
  removeCoupon: (ids: string) => Promise<any>;
  setCartUiState: Dispatch<SetStateAction<CartUiState>>;
  emptyCart: () => void;
}

export interface ProductPricesType {
  sale: string | undefined | null;
  regular: string | undefined;
}

const CartContextValues: CartContextType = {
  isLoading: false,
  isUpdatingProduct: false,
  isAddingProduct: false,
  isUpdatingShippingMethod: false,
  isApplyingCoupon: false,
  isRemovingCoupon: false,

  total: '',
  subtotal: '',
  isEmpty: undefined,
  hasCoupons: false,
  shippingTotal: '',
  chosenShippingMethods: '',
  itemCount: null,
  items: [],
  appliedCoupons: null,

  error: undefined,
  cartUiState: {
    open: false,
    productAdded: false,
  },

  setCartUiState: () => {},
  refetch: () => new Promise<ApolloQueryResult<any>>((resolve) => resolve),
  addProduct: () => undefined,
  updateShippingMethod: () => {},
  updateProductQuantity: () => {},
  applyCoupon: () => new Promise<any>((resolve) => resolve),
  removeCoupon: () => new Promise<any>((resolve) => resolve),
  emptyCart: () => {},
};

export const CartContext = React.createContext<CartContextType>(CartContextValues);

export const useCartContext = () => useContext(CartContext);
