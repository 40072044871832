import React from 'react';

interface Props {
  loading?: boolean;
  loadingPlaceholder: JSX.Element;
  children: React.ReactNode;
}

export const HandleLoadingState = (props: Props) => {
  if (props.loading) {
    return <>{props.loadingPlaceholder}</>;
  }
  return <>{props.children}</>;
};
