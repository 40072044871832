import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCartContext } from '../../../lib/context/CartContext/CartContext';

import { fields } from '../../../lib/translation/strings';

import { ProductPrices } from '../../shared/ProductPrices/ProductPrices';

import './OrderDetails.scss';
import { Image } from '../../shared/Image/Image';
import { getProductPricesCart } from '../../../lib/helpers/getProductPrices';

export const OrderDetails = () => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  return (
    <div className='OrderDetails'>
      <div className='OrderDetails__table'>
        <h4 className='OrderDetails__table__title'>{t(fields.bagSummary)}</h4>
        <ul className='CartSidebar-list'>
          {cartCtx?.items?.map((item, i) => {
            const product = item.product;

            const prices = getProductPricesCart(item);

            return (
              <li key={`${item?.product?.node?.id}-${i}`} className='CartSidebar-list-item'>
                <div className='CartSidebar-list-item-image'>
                  <Image src={product?.node?.image?.mediaItemUrl} alt={item.product?.node?.name} />
                </div>
                <div className='CartSidebar-list-item-info'>
                  <h4>{product?.node?.name}</h4>
                  <div className='d-flex align-items-center mb-1'>
                    <h5>{t(fields.quantity)}:</h5>
                    <span>{item.quantity}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <h5>{t(fields.price)}:</h5>
                    <ProductPrices sale={prices.sale} regular={prices.regular} />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
