import React from 'react';
import ContentLoader from 'react-content-loader';

import './CartItem.scss';

export const CartItemLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={392}
      viewBox="0 0 300 392"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="CartItem"
    >
      <rect x="200" y="0" width="100" height="15" className="mobilenone" />
      <rect x="0" y="30" width="300" height="365" className="loaderhe" />
    </ContentLoader>
  );
};
