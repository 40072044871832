import React from 'react';
import { ThemeLink } from '../../shared/ThemeLink/ThemeLink';
import ContentLoader from 'react-content-loader';

export const HomeOtherCollectionsLoader = () => {
  return (
    <div className='HomeOtherCollections__collection'>
      <div className='HomeOtherCollections__collection-img'>
        <ContentLoader
          speed={2}
          width={360}
          height={450}
          viewBox='0 0 360 450'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='360' height='450' />
        </ContentLoader>
      </div>
      <div>
        <h6>
          <ContentLoader
            speed={2}
            width={200}
            height={25}
            viewBox='0 0 200 25'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='200' height='25' />
          </ContentLoader>
        </h6>
        <ThemeLink to=''>
          <ContentLoader
            speed={2}
            width={80}
            height={20}
            viewBox='0 0 80 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='80' height='20' />
          </ContentLoader>
        </ThemeLink>
      </div>
    </div>
  );
};
