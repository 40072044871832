import React, { useRef } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { actions, errors } from '../../lib/translation/strings';

import { ProductItem } from './ProductItem';
import { ProductItemLoader } from './ProductItemLoader';
import { BulkLoader } from '../shared/BulkLoader/BulkLoader';
import { InfoMessage } from '../shared/InfoMessage/InfoMessage';
import { HandleLoadingState } from '../shared/HandleLoadingState/HandleLoadingState';
import { Product } from '../../types/productTypes';
import { useInfiniteScroll } from '../../lib/hooks/useInfiniteScroll';

import './ProductsList.scss';
import { Spinner } from 'reactstrap';

interface Props {
  big?: boolean;
  className?: string;
  error?: string;
  loading?: boolean;
  products?: Product[];
  isLoading: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  pageInfo: boolean;
  loadingPlaceholderNumber?: number;
  onLoadMore: () => void;
}

export const ProductsList = (props: Props) => {
  const { t } = useTranslation();
  const loaderRef = useRef(null);
  const noProductsAvailable = !props.products || Number(props.products.length) === 0;

  useInfiniteScroll(loaderRef, () => {
    if (!props.pageInfo || props.loading || props.isLoading) return;
    props.onLoadMore();
  });

  return (
    <>
      <div
        ref={loaderRef}
        className={cs(
          'ProductsList',
          !props.loading && noProductsAvailable && 'ProductsList--empty',
          props.className,
        )}
      >
        <HandleLoadingState
          loading={props.loading}
          loadingPlaceholder={
            <BulkLoader length={props.loadingPlaceholderNumber || 6}>
              <ProductItemLoader />
            </BulkLoader>
          }
        >
          {noProductsAvailable ? (
            <InfoMessage
              title={t(errors.noProducts)}
              buttonTitle={t(actions.shop)}
              buttonLink='/shop'
            />
          ) : (
            props.products?.map((product) => {
              return <ProductItem key={product.id} product={product} />;
            })
          )}
        </HandleLoadingState>
      </div>
      {(props.loading || props.isLoading) && (
        <div className='ProductsList__loading'>
          <Spinner
            type='border'
            color='primary'
            style={{
              width: '2rem',
              height: '2rem',
            }}
          />
        </div>
      )}
    </>
  );
};
