import gql from 'graphql-tag';
import { Coupon } from '../types/generalTypes';
import { Product } from '../types/productTypes';

export interface CartNode {
  key: string;
  quantity: number;
  extraData: {
    id: string;
    key: string;
    value: string;
  }[];
  variation: {
    node: {
      id: string;
      databaseId: number;
      sku: string;
      onSale: boolean;
      salePrice: string;
      regularPrice: string;
      stockQuantity: number;
      attributes: {
        nodes: [
          {
            id: string;
            name: string;
            value: string;
          },
          {
            id: string;
            name: string;
            value: string;
          },
        ];
      };
    };
  };
  product: {
    node: Product;
  };
}

export interface GetCartResponse {
  cart: {
    isEmpty: boolean;
    subtotal?: string;
    total: string;
    discountTotal: string;
    shippingTotal: string;
    chosenShippingMethods: string[];
    appliedCoupons?: Coupon[];
    contents: {
      itemCount: number;
      nodes: CartNode[];
    };
  };
}

export const getCart = gql`
  query getCart {
    cart {
      isEmpty
      subtotal
      total
      discountTotal
      shippingTotal
      chosenShippingMethods
      appliedCoupons {
        code
        discountAmount(format: RAW)
        discountTax(format: RAW)
        description
        # nodes {
        #   id
        #   code
        #   amount
        #   discountType
        # }
      }
      contents {
        itemCount
        nodes {
          key
          quantity
          extraData {
            id
            key
            value
          }
          variation {
            node {
              id
              databaseId
              sku
              onSale
              salePrice(format: FORMATTED)
              regularPrice(format: FORMATTED)
              stockQuantity
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
          product {
            node {
              id
              slug
              databaseId
              name
              onSale
              type
              image {
                id
                mediaItemUrl
                srcSet(size: LARGE)
              }
              ... on SimpleProduct {
                id
                name
                salePrice(format: FORMATTED)
                regularPrice(format: FORMATTED)
                stockQuantity
              }
              ... on VariableProduct {
                id
                name
                salePrice(format: FORMATTED)
                regularPrice(format: FORMATTED)
                stockQuantity
              }
            }
          }
        }
      }
    }
  }
`;
