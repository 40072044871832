import gql from 'graphql-tag';

export const REFRESH_JWT_AUTH_TOKEN = gql`
  mutation refreshJwtAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(
      input: {
        clientMutationId: "refresh-token-mutation"
        jwtRefreshToken: $refreshToken
      }
    ) {
      authToken
      clientMutationId
    }
  }
`;
