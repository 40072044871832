import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Input, FormFeedback } from 'reactstrap';

import {
  PAYMENT_METHODS,
  PaymentMethodsResults,
  PaymentMethod as PaymentMethodProps,
} from '../../../queries/checkoutQueries';
import { fields } from '../../../lib/translation/strings';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import { CheckoutFormik } from '../../../lib/formik/useCheckoutFormik';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { ProductSingleLoader } from '../../../pages/ProductSingle/ProductSingleLoader';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';

import './PaymentMethod.scss';

interface Props {
  formik: CheckoutFormik;
}

export const PaymentMethod = (props: Props) => {
  const { t } = useTranslation();
  const paymentMethods = useQuery<PaymentMethodsResults>(PAYMENT_METHODS);
  const isValid = !!props.formik.errors.paymentMethod && props.formik.touched.paymentMethod;

  return (
    <div className='PaymentMethod'>
      <HandleLoadingState
        loading={paymentMethods.loading}
        loadingPlaceholder={
          <BulkLoader length={1}>
            <ProductSingleLoader />
          </BulkLoader>
        }
      >
        <ApolloErrorGuard error={paymentMethods.error}>
          <h4 className='PaymentMethod__title'>
            <span>3</span>
            {t(fields.payment)}
          </h4>
          {paymentMethods.data?.paymentGateways?.nodes?.map((paymentMethod: PaymentMethodProps) => {
            return (
              <div className='PaymentMethod__item' key={paymentMethod.id}>
                <label htmlFor={`pm-${paymentMethod.id}`}>
                  <Input
                    type='radio'
                    id={`pm-${paymentMethod.id}`}
                    name='paymentMethod'
                    value={paymentMethod.id}
                    hidden
                    onChange={props.formik.handleChange}
                  />
                  <span className='name'>{paymentMethod.title}</span>
                </label>
              </div>
            );
          })}
          {isValid && (
            <FormFeedback className='mt-1 is--invalid'>
              {props.formik.errors.paymentMethod}
            </FormFeedback>
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
