import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Input, Row } from 'reactstrap';
import { fields, formInputs } from '../../../lib/translation/strings';
import { HeadingTitle } from '../../shared/HeadingTitle/HeadingTitle';
import { ProfileAddressFormik } from '../../../lib/formik/profile/useProfileAddressFormik';
import { SelectCountry } from '../../shared/SelectCountry/SelectCountry';
import { SelectState } from '../../shared/SelectState/SelectState';

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressShipping = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div className='ProfileAddressShipping'>
      <Row>
        <Col xs='12' sm='12'>
          <HeadingTitle title={t(fields.shippingAddress)} />
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='firstName'>{t(formInputs.name)}</Label>
            <Input
              id='firstName'
              type='text'
              name='shipping.firstName'
              placeholder={t(formInputs.name)}
              onChange={formik.handleChange}
              value={formik.values.shipping.firstName || ''}
            />
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='lastName'>{t(formInputs.lastName)}</Label>
            <Input
              id='lastName'
              type='text'
              name='shipping.lastName'
              onChange={formik.handleChange}
              placeholder={t(formInputs.lastName)}
              value={formik.values.shipping.lastName || ''}
            />
          </FormGroup>
        </Col>

        <Col xs='12'>
          <FormGroup className='form-group'>
            <Label for='address2'>{t(formInputs.address)}</Label>
            <Input
              id='address2'
              type='text'
              name='shipping.address1'
              onChange={formik.handleChange}
              placeholder={t(formInputs.address)}
              value={formik.values.shipping.address1 || ''}
            />
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='city'>{t(fields.city)}</Label>
            <Input
              id='city'
              type='text'
              name='shipping.city'
              onChange={formik.handleChange}
              placeholder={t(fields.city)}
              value={formik.values.shipping.city || ''}
              invalid={!!formik.errors.shipping?.city && formik.touched.shipping?.city}
            />
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='postcode'>{t(fields.zipCode)}</Label>
            <Input
              id='postcode'
              type='text'
              name='shipping.postcode'
              onChange={formik.handleChange}
              placeholder={t(fields.zipCode)}
              value={formik.values.shipping.postcode || ''}
              invalid={!!formik.errors.shipping?.postcode && formik.touched.shipping?.postcode}
            />
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='state'>{t(fields.state)}</Label>
            <SelectState
              id='state'
              name='state'
              country={formik.values.shipping.country}
              hasError={!!formik.errors.shipping?.state && formik.touched.shipping?.state}
              errorMessage={formik.errors.shipping?.state}
              onSelect={(state: string) => {
                formik.setFieldValue('shipping.state', state);
              }}
              initialValue={formik.values.shipping.state}
            />
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='country'>{t(fields.country)}</Label>
            <SelectCountry
              id='country'
              name='country'
              hasError={!!formik.errors.shipping?.country && formik.touched.shipping?.country}
              errorMessage={formik.errors.shipping?.country}
              onSelect={(country: string) => {
                formik.setFieldValue('shipping.country', country);
              }}
              initialValue={formik.values.shipping.country}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
