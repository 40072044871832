import React from 'react';
import ContentLoader from 'react-content-loader';

export const ContentPageLoader = () => (
  <ContentLoader
    speed={2}
    width={1200}
    height={500}
    viewBox="0 0 800 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="350" height="25" />
    <rect x="0" y="40" rx="0" ry="0" width="570" height="15" />
    <rect x="0" y="60" rx="0" ry="0" width="570" height="15" />
    <rect x="0" y="80" rx="0" ry="0" width="570" height="15" />
    <rect x="0" y="100" rx="0" ry="0" width="570" height="15" />
    <rect x="0" y="120" rx="0" ry="0" width="500" height="15" />
    <rect x="0" y="140" rx="0" ry="0" width="450" height="15" />
  </ContentLoader>
);
