import React, { useContext } from 'react';

import { NotificationState } from './NotificationContextProvider';

export interface NotificationContextType {
  // Notification details
  notifications: NotificationState[];

  // methods
  warning: (message: string, autoClose?: boolean, className?: string) => void;
  success: (message: string, autoClose?: boolean, className?: string) => void;
  danger: (message: string, autoClose?: boolean, className?: string) => void;
  remove: (message: string) => void;
  removeAll: () => void;
}

const NotificationContextValues: NotificationContextType = {
  notifications: [],

  warning: () => {},
  success: () => {},
  danger: () => {},
  remove: () => {},
  removeAll: () => {}
};

export const NotificationContext = React.createContext<NotificationContextType>(
  NotificationContextValues
);

export const useNotification = () => useContext(NotificationContext);
