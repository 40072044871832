import { RouteProps } from 'react-router-dom';

import { Login } from '../pages/Login/Login';
import { Register } from '../pages/Register/Register';
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { Profile } from '../pages/Profile/Profile';
import { ShopByCategory } from '../pages/Shop/ShopByCategory';
import { Cart } from '../pages/Cart/Cart';
import { Checkout } from '../pages/Checkout/Checkout';
import { ProductSingle } from '../pages/ProductSingle/ProductSingle';
import { Contact } from '../pages/Contact/Contact';
// import { Faq } from '../pages/Faq/Faq';
import { Wishlist } from '../pages/Wishlist/Wishlist';
import { Home } from '../pages/Home/Home';
import { CheckoutConfirmation } from '../pages/Checkout/CheckoutConfirmation';
import { SearchPage } from '../pages/SearchPage/SearchPage';
import { ShopBySale } from '../pages/Shop/ShopBySale';
import { Blog } from '../pages/Blog/Blog';
import { BlogSingle } from '../pages/Blog/BlogSingle';
import { ContentPage } from '../components/shared/ContentPage/ContentPage';
import { BookAppointment } from '../pages/BookAppointment/BookAppointment';
import { CheckoutFail } from '../pages/Checkout/CheckoutFail';
// import { BookAppointment } from '../pages/BookAppointment/BookAppointment';

export enum RouteType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

type AppRoute = {
  type?: RouteType;
} & RouteProps;

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    path: 'login',
    Component: Login,
  },
  {
    type: RouteType.PUBLIC,
    path: 'register',
    Component: Register,
  },
  {
    type: RouteType.PUBLIC,
    path: 'forgot-password',
    Component: ForgotPassword,
  },
  {
    type: RouteType.PUBLIC,
    path: 'reset-password',
    Component: ResetPassword,
  },

  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: 'my-profile',
    Component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    path: 'my-profile/security',
    Component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    path: 'my-profile/orders',
    Component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    path: 'my-profile/orders/:slug',
    Component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    path: 'my-profile/address',
    Component: Profile,
  },

  // Normal Routes
  {
    path: '/',
    Component: Home,
  },
  {
    path: ':slug',
    Component: ContentPage,
  },
  {
    path: 'my-cart',
    Component: Cart,
  },
  {
    path: 'checkout',
    Component: Checkout,
  },
  {
    path: 'checkout/fail',
    Component: CheckoutFail,
  },
  {
    path: 'checkout/:orderId',
    Component: CheckoutConfirmation,
  },
  {
    path: 'sale',
    Component: ShopBySale,
  },
  {
    path: 'shop/:parentCategory?/:childCategory?',
    Component: ShopByCategory,
  },
  {
    path: 'product/:slug',
    Component: ProductSingle,
  },
  {
    path: 'contact',
    Component: Contact,
  },
  {
    path: 'book-your-appointment',
    Component: BookAppointment,
  },
  // {
  //   path: 'faq',
  //   Component: Faq,
  // },
  {
    path: 'my-list',
    Component: Wishlist,
  },
  {
    path: 'search',
    Component: SearchPage,
  },
  {
    path: 'blog',
    Component: Blog,
  },
  {
    path: 'blog/:slug',
    Component: BlogSingle,
  },
];
