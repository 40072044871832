import React from 'react';
import { useTranslation } from 'react-i18next';

import { messages } from '../../../lib/translation/strings';

import { BlockButton } from '../BlockButton/BlockButton';

import './EmptyPage.scss';

interface Props {
  title: string;
  buttonText?: string;
  description: string;
  redirectLink?: string;
}

export const EmptyPage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="EmptyPage my-5 w-100 d-flex flex-column align-items-center px-2">
      <h2 className="EmptyPage__title">{props.title}</h2>
      <p className="EmptyPage__description text-center">{props.description}</p>
      <BlockButton link to={`/${props.redirectLink || 'shop'}`}>
        {props.buttonText || t(messages.allRight)}
      </BlockButton>
    </div>
  );
};
