import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getFormatedDate } from '../../../lib/helpers/getFormatedDate';
import { removeHTMLEntities } from '../../../lib/helpers/removeHTMLEntities';

import {
  getOrderById,
  GetOrderByIdResponse,
  GetOrderByIdVariables,
} from '../../../queries/ordersQueries';
import { OrdersLoader } from './OrdersLoader';
import { OrderSingleLoader } from './OrderSingleLoader';
import { EmptyPage } from '../../shared/EmptyMessage/EmptyPage';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';

import './ProfileOrders.scss';
import { errors, fields, formInputs } from '../../../lib/translation/strings';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

export const ProfileOrdersSingle = () => {
  const { slug } = useParams<{ slug: string }>();
  const { t } = useTranslation();
  const orderDetails = useQuery<GetOrderByIdResponse, GetOrderByIdVariables>(getOrderById, {
    variables: {
      id: `${slug}`,
    },
  });

  const orderData = orderDetails.data?.order;
  const orderList = orderData?.lineItems?.nodes ?? [];

  return (
    <HandleLoadingState
      loading={orderDetails.loading}
      loadingPlaceholder={
        <div className='ProfileOrdersSingle' style={{ paddingTop: '55px' }}>
          <div className='ProfileOrdersSingle__left-side'>
            <div className='ProfileOrdersSingle__item' style={{ marginTop: '-45px' }}>
              <OrderSingleLoader />
            </div>
            <div className='ProfileOrdersSingle__item'>
              <OrdersLoader />
            </div>
            <div className='ProfileOrdersSingle__item'>
              <OrdersLoader />
            </div>
          </div>
          <div className='ProfileOrdersSingle__right-side' />
        </div>
      }
    >
      {!orderData ? (
        <EmptyPage
          title={t(fields.order)}
          buttonText={t(fields.myOrders)}
          redirectLink='my-profile/orders'
          description={t(errors.orderNotFound)}
        />
      ) : (
        <div className='ProfileOrdersSingle'>
          <span className='ProfileOrdersSingle__order-nr'>
            <strong>{t(fields.order)}: </strong>
            {orderData?.orderNumber}
          </span>
          <div className='ProfileOrdersSingle__left-side'>
            <Row>
              {orderList.map((item, index: number) => {
                return (
                  <Col key={`${item.orderId}-${index}`} xs={12} md={4}>
                    <div className='ProfileOrdersSingle__item'>
                      <div className='ProfileOrdersSingle__thumb'>
                        <img src={item.product.node.image.sourceUrl} alt='' />
                      </div>
                      <div className='ProfileOrdersSingle__content'>
                        <span className='ProfileOrdersSingle__left-side__item'>
                          {item.product.node.name}
                        </span>
                        <span className='ProfileOrdersSingle__left-side__item'>
                          <strong>{removeHTMLEntities(item.product.node.regularPrice)}</strong>
                        </span>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className='ProfileOrdersSingle__right-side'>
            <Row>
              <Col xs={12} md={4}>
                <div className='ProfileOrdersSingle__address'>
                  <h4>{t(fields.billing)}: </h4>
                  {orderData.billing.firstName && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.firstName)}: </strong>
                      {orderData.billing.firstName}
                    </span>
                  )}
                  {orderData.billing.lastName && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.lastName)}: </strong>
                      {orderData.billing.lastName}
                    </span>
                  )}
                  {orderData.billing.address1 && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.address)}: </strong>
                      {orderData.billing.address1}
                    </span>
                  )}
                  {orderData.billing.state && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.country)}: </strong>
                      {orderData.billing.state}
                    </span>
                  )}
                  {orderData.billing.postcode && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.zipCode)}: </strong>
                      {orderData.billing.postcode}
                    </span>
                  )}
                  {orderData.billing.phone && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.phoneNumber)}: </strong>
                      {orderData.billing.phone}
                    </span>
                  )}
                  {orderData.billing.email && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.email)}: </strong>
                      {orderData.billing.email}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className='ProfileOrdersSingle__address'>
                  <h4>{t(fields.shipping)}: </h4>
                  {orderData.shipping.firstName && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.firstName)}: </strong>
                      {orderData.shipping.firstName}
                    </span>
                  )}
                  {orderData.shipping.lastName && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.lastName)}: </strong>
                      {orderData.shipping.lastName}
                    </span>
                  )}
                  {orderData.shipping.address1 && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.address)}: </strong>
                      {orderData.shipping.address1}
                    </span>
                  )}
                  {orderData.shipping.state && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.country)}: </strong>
                      {orderData.shipping.state}
                    </span>
                  )}
                  {orderData.shipping.postcode && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.zipCode)}: </strong>
                      {orderData.shipping.postcode}
                    </span>
                  )}
                  {orderData.shipping.phone && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.phoneNumber)}: </strong>
                      {orderData.shipping.phone}
                    </span>
                  )}
                  {orderData.shipping.email && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(formInputs.email)}: </strong>
                      {orderData.shipping.email}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className='ProfileOrdersSingle__address'>
                  <h4>{t(fields.orderDetails)}: </h4>
                  {orderData.date && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.orderDate)}: </strong>
                      {getFormatedDate(orderData.date)}
                    </span>
                  )}
                  {orderData.status && (
                    <span className='ProfileOrdersSingle__right-side__contact-item'>
                      <strong>{t(fields.status)}: </strong>
                      {t(orderData.status)}
                    </span>
                  )}
                  {orderData.total && (
                    <span className='ProfileOrdersSingle__right-side__contact-item right'>
                      <strong>{t(fields.total)}: </strong>
                      {removeHTMLEntities(orderData.total)}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </HandleLoadingState>
  );
};
