import React, { useEffect, useState } from 'react';
import { useRouter } from '../../hooks/useRouter';
import { useApolloClient, useMutation } from '@apollo/client';

import { isTokenExpired } from '../../helpers/isTokenExpired';
import { AuthContext, AuthContextType } from './AuthContext';

// Queries
import { GET_VIEWER_QUERY } from '../../../queries/userQueries';
import { deleteLocalValue, getLocalValues, setLocalValue } from '../../helpers/localStorageHelpers';
import {
  FORGOT_PASSWORD_MUTATION,
  ForgotPasswordProps,
  ForgotPasswordResults,
  LOGIN_MUTATION,
  REGISTER_MUTATION,
  RegisterProps,
  RegisterResults,
  RESET_PASSWORD_MUTATION,
  ResetPasswordProps,
  ResetPasswordResults,
} from '../../../queries/mutations/userMutations';

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export interface AuthContextUserProps {
  id: string;
  email: string;
  userId: number;
  lastName: string;
  username: string;
  firstName: string;
  wishlist?: number[];
}

interface AuthContextProviderState {
  loading: boolean;
  user: AuthContextUserProps | undefined;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const router = useRouter();
  const client = useApolloClient();
  const localValues = getLocalValues();
  // const langPrefix = i18n.language === "sq" ? "" : "/" + i18n.language;
  const langPrefix = '/';

  const [loginMutation, { loading: isLoggingIn }] = useMutation(LOGIN_MUTATION);

  const [registerMutation, { loading: isRegistering }] = useMutation<
    RegisterResults,
    RegisterProps
  >(REGISTER_MUTATION);

  const [forgotPasswordMutation] = useMutation<ForgotPasswordResults, ForgotPasswordProps>(
    FORGOT_PASSWORD_MUTATION,
  );

  const [resetPasswordMutation] = useMutation<ResetPasswordResults, ResetPasswordProps>(
    RESET_PASSWORD_MUTATION,
  );

  const [isLoadingData, setIsLoadingData] = useState(false);

  const [state, setState] = useState<AuthContextProviderState>({
    loading: true,
    user: undefined,
    error: undefined,
    loginError: undefined,
    registerError: undefined,
  });

  //On route change: reset errors
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: undefined,
      loginError: undefined,
      registerError: undefined,
    }));
  }, [router.location.pathname]);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    if (!localValues.refreshToken || isTokenExpired(localValues.refreshToken)) {
      setState({
        ...state,
        user: undefined,
        loading: false,
      });
      return;
    }
    try {
      const res = await client.query({
        query: GET_VIEWER_QUERY,
      });

      if (res.errors) {
        setState({
          ...state,
          loading: false,
          error: res.errors.toString(),
        });
      }

      if (res?.data?.viewer) {
        const user = res?.data?.viewer;

        const wishlistProducts = user.wishListProducts.wishlistProducts
          ? JSON.parse(user.wishListProducts.wishlistProducts)
          : [];

        setState({
          ...state,
          user: {
            id: user.id,
            email: user.email,
            userId: user.userId,
            lastName: user.lastName,
            username: user.username,
            firstName: user.firstName,
            wishlist: wishlistProducts,
          },
          error: undefined,
          loading: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: (err as Error).toString(),
        user: undefined,
        loading: false,
      });
    }
  };

  const updateUser = (values: AuthContextUserProps) => {
    setState({
      ...state,
      user: {
        ...values,
      },
    });
  };

  const login = async (username: string, password: string, redirect: boolean = true) => {
    const localValues = getLocalValues();
    try {
      const res = await loginMutation({
        variables: {
          username: username,
          password: password,
          wishlist: localValues.wishlist,
        },
      });

      if (!res?.data?.login) {
        return;
      }
      const user = res.data.login.user;

      const wishlistProducts = user.wishListProducts.wishlistProducts
        ? JSON.parse(user.wishListProducts.wishlistProducts)
        : [];

      // TODO: Re check how the local storage values get deleted
      setLocalValue('authToken', res.data.login.authToken);
      setLocalValue('refreshToken', res.data.login.refreshToken);
      deleteLocalValue('wishlist');

      setState({
        ...state,
        loginError: undefined,
        user: {
          id: user.id,
          email: user.email,
          userId: user.userId,
          lastName: user.lastName,
          username: user.username,
          firstName: user.firstName,
          wishlist: wishlistProducts,
        },
      });

      const hasRedirectParam = router.location.search.includes('redirect');
      const returnURL =
        redirect && hasRedirectParam
          ? decodeURIComponent(router.location.search.split('redirect=')[1])
          : '/';

      router.push(returnURL);
    } catch (e) {
      setState({
        ...state,
        loginError: (e as Error).message,
      });
    }
  };

  const register = async (input: RegisterProps) => {
    try {
      const res = await registerMutation({
        variables: {
          firstname: input.firstname,
          lastname: input.lastname,
          municipality: input.municipality,
          address: input.address,
          password: input.password,
          telephone: input.telephone,
          email: input.email,
        },
      });

      if (res.data?.registerCustomer) {
        const user = res.data?.registerCustomer.customer;

        setState({
          ...state,
          registerError: undefined,
          user: {
            id: user.id,
            email: user.email,
            userId: user.databaseId,
            lastName: user.lastName,
            username: user.username,
            firstName: user.firstName,
          },
        });

        setLocalValue('authToken', res.data.registerCustomer.authToken);
        setLocalValue('refreshToken', res.data.registerCustomer.refreshToken);

        const hasRedirectParam = router.location.search.includes('redirect');
        const returnURL = hasRedirectParam
          ? decodeURIComponent(router.location.search.split('redirect=')[1])
          : '/';

        router.push(returnURL);
      }
    } catch (e) {
      setState({
        ...state,
        registerError: (e as Error).message,
      });
    }
  };

  const forgotPassword = (username: string) => {
    return forgotPasswordMutation({
      variables: {
        username: username,
      },
    });
  };

  const resetPassword = (input: ResetPasswordProps) => {
    return resetPasswordMutation({
      variables: {
        key: input.key,
        login: input.login,
        password: input.password,
      },
    });
  };

  const logout = async () => {
    localStorage.removeItem('VALENTINE_COUTURE_USER');
    setState({
      ...state,
      user: undefined,
    });
    await client.cache.reset();
    router.push(langPrefix);
  };

  const context: AuthContextType = {
    isAuthenticated: state.user !== undefined,
    isLoading: state.loading,
    isLoadingData: isLoadingData,
    isLoggingIn: isLoggingIn,
    isRegistering: isRegistering,
    error: state.error,
    loginError: state.loginError,
    registerError: state.registerError,
    user: state.user,
    updateUser,
    logout: logout,
    checkAuthentication,
    login: login,
    register: register,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    setIsLoadingData: setIsLoadingData,
  };

  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};
