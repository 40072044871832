import React, { CSSProperties, useRef, useState } from 'react';
import cs from 'classnames';
import defaultLogo from '../../../assets/images/default.png';

import './Image.scss';

interface Props {
  src?: string;
  alt?: string;
  srcSet?: string;
  className?: string;
  dynamicRef?: boolean;
  style?: CSSProperties;
  imagePlaceholder?: string;
}

export const Image = (props: Props) => {
  const [containImage, setContainImage] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const imgPlaceholder = props.imagePlaceholder ?? defaultLogo;

  const handleOnLoad = () => {
    if (!imgRef.current) {
      return;
    }

    setContainImage(
      imgRef.current?.naturalHeight <= imgRef.current?.naturalWidth
    );
  };

  const handleRef = (el: HTMLImageElement) => {
    if (!props.dynamicRef) {
      return;
    }

    imgRef.current = el;
  };

  return props.src ? (
    <img
      src={props.src}
      alt={props?.alt}
      style={props.style}
      ref={handleRef}
      onLoad={handleOnLoad}
      srcSet={props.srcSet}
      className={cs('Image', props.className, {
        'Image--contain': containImage
      })}
    />
  ) : (
    <span className="Image--default">
      <img src={imgPlaceholder} alt={props.alt || 'Valentine Couture logo'} />
    </span>
  );
};
