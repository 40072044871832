import React from 'react';
import queryString from 'query-string';
import { Container, Row } from 'reactstrap';

import { useRouter } from '../../lib/hooks/useRouter';

import { SearchResults } from '../../components/SearchResults/SearchResults';

export const SearchPage = () => {
  const { location } = useRouter();
  const params = queryString.parse(location.search);
  const searchValue = params.s || '';

  const keyword =
    searchValue && Array.isArray(searchValue)
      ? searchValue.join()
      : searchValue;

  return (
    <div className="SearchPage">
      <Container>
        <Row>
          <SearchResults searchTerm={keyword} />
        </Row>
      </Container>
    </div>
  );
};
