import React from 'react';
import { Container } from 'reactstrap';
import { ProfileSidebar } from './ProfileSidebar';
import { ProfileContent } from './ProfileContent';

import './Profile.scss';

export const Profile = () => {
  return (
    <Container>
      <div className='Profile'>
        <ProfileSidebar />
        <ProfileContent />
      </div>
    </Container>
  );
};
