import React from 'react';
import './ProductPrices.scss';
import ContentLoader from 'react-content-loader';

export const ProductPricesLoader = () => {
  return (
    <div className='ProductPrices'>
      <h4 className='regular'>
        <ContentLoader
          speed={2}
          width={100}
          height={24}
          viewBox='0 0 100 24'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' width='100' height='24' />
        </ContentLoader>
      </h4>
    </div>
  );
};
