import { ProductSingleProps, ProductSingleVariationProps } from '../../../queries/archive';
import { errors, formValidation } from '../../../lib/translation/strings';
import { CartProduct } from '../../../types/cartTypes';
import { useTranslation } from 'react-i18next';
import { canAddToCart } from './helpers/canAddToCart';
import { useNotification } from '../../../lib/context/NotificationContext/NotificationContext';
import { useCartContext } from '../../../lib/context/CartContext/CartContext';
import { getFirstVariationWithQuantity } from './helpers/getFirstVariationWithQuantity';
import { getVariationAttributeValue } from './helpers/getVariationAttributeValue';
import { getProductDetails } from './helpers/getProductDetails';
import { hasProductDetails } from './helpers/hasProductDetails';
import { getStockQuantity } from './helpers/getStockQuantity';
import { getProductPrices } from './helpers/getProductPrices';
import { getProductVariationSlug } from './helpers/getProductVariationSlug';
import { CUSTOM_SIZE_ATTRIBUTES } from '../../../constants';

export const useProductSingleHelpers = () => {
  const notification = useNotification();
  const cartCtx = useCartContext();
  const { t } = useTranslation();

  const getCartItem = (productID: number, databaseId: string | undefined) => {
    const items =
      cartCtx?.items?.filter((item: CartProduct) => item.product.node.databaseId === productID) ||
      [];

    if (items.length === 0) {
      return;
    }

    if (databaseId) {
      return items.find((item: CartProduct) => item.variation.node.id === databaseId);
    }

    return items[0] as CartProduct;
  };

  const validateAddToCartProduct = (
    product: ProductSingleProps,
    filteredVariations: ProductSingleVariationProps[],
    quantity: number,
    customAttribute?: { [key: string]: string },
  ) => {
    if (cartCtx.isAddingProduct || cartCtx?.cartUiState?.productAdded) {
      return;
    }

    if (product.type === 'SIMPLE' && !product.stockQuantity) {
      notification.warning(t(errors.productNotInStock)); //This product is out of stock.
      return;
    }

    if (product.type === 'VARIABLE') {
      if (filteredVariations.length !== 1) {
        notification.warning(t(formValidation.selectVariation)); //Please select a variation to add it to cart.
        return;
      }

      if (!filteredVariations[0].stockQuantity) {
        notification.warning(t(errors.variationOutOfStock)); //This variation of the product is out of stock.
        return;
      }

      const isCustom = filteredVariations[0].attributes.nodes.some?.(
        ({ value }) => value === 'custom',
      );

      if (
        isCustom &&
        (!customAttribute || CUSTOM_SIZE_ATTRIBUTES.some((slug) => !customAttribute?.[slug]))
      ) {
        notification.warning(t(errors.customAttributeMissingValues));
        return;
      }
    }

    const variation = filteredVariations[0];
    const cartItem = getCartItem(product.databaseId, variation?.id);

    const productStockQuantity =
      cartItem?.product?.node.type === 'SIMPLE'
        ? cartItem?.product?.node?.stockQuantity
        : cartItem?.variation?.node?.stockQuantity;

    if (cartItem && !canAddToCart(cartItem, quantity, variation?.id)) {
      notification.warning(
        t(errors.cannotAddAmountOfStockToCart, {
          stockQuantity: productStockQuantity || '?',
          quantity: cartItem?.quantity,
        }),
      );
      return;
    }

    return true;
  };

  return {
    //validations
    canAddToCart,
    validateAddToCartProduct,
    hasProductDetails,

    //  getters
    getCartItem,
    getFirstVariationWithQuantity,
    getVariationAttributeValue,
    getProductDetails,
    getStockQuantity,
    getProductPrices,
    getProductVariationSlug,
  };
};
