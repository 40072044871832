import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

import { ProfileAccountFormik } from '../../../lib/formik/profile/useProfileAccountFormik';
import { actions, formInputs } from '../../../lib/translation/strings';
import { BlockButton } from '../../shared/BlockButton/BlockButton';

interface Props {
  disable: boolean;
  loading: boolean;
  formik: ProfileAccountFormik;
}
export const ProfileAccountForm = (props: Props) => {
  const { t } = useTranslation();
  const { formik } = props;

  return (
    <Row>
      <Col xs='12'>
        <FormGroup className='form-group'>
          <Label for='email'>{t(formInputs.email)}</Label>
          <Input disabled type='email' name='email' value={formik.values.email} />
        </FormGroup>
      </Col>
      <Col xs='12'>
        <FormGroup className='form-group'>
          <Label for='username'>{t(formInputs.username)}</Label>
          <Input type='text' name='username' disabled value={formik.values.username} />
        </FormGroup>
      </Col>
      <Col xs='12'>
        <FormGroup className='form-group'>
          <Label for='firstName'>{t(formInputs.name)}</Label>
          <Input
            type='text'
            name='firstName'
            onChange={formik.handleChange}
            value={formik.values.firstName}
            invalid={!!formik.errors.firstName && formik.touched.firstName}
          />
          <FormFeedback>{formik.errors.firstName}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs='12'>
        <FormGroup className='form-group'>
          <Label for='lastName'>{t(formInputs.lastName)}</Label>
          <Input
            type='text'
            name='lastName'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            invalid={!!formik.errors.lastName && formik.touched.lastName}
          />
          <FormFeedback>{formik.errors.lastName}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs='12'>
        <FormGroup className='form-group'>
          <BlockButton
            type='submit'
            className='w-100-mobile'
            disabled={!formik.dirty || props.loading}
            onClick={formik.submitForm}
          >
            {props.loading ? t(actions.saving) : t(actions.save)}
          </BlockButton>
        </FormGroup>
      </Col>
    </Row>
  );
};
