import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setLocalValue } from '../lib/helpers/localStorageHelpers';
import { getNewToken } from './apolloHelpers';

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!graphQLErrors) {
    return;
  }
  let hasExpiredTokenError = false;
  for (const err of graphQLErrors) {
    const errM = err as any;
    if (
      errM.debugMessage &&
      errM.debugMessage.indexOf('Expired token') >= 0 &&
      !hasExpiredTokenError
    ) {
      hasExpiredTokenError = true;
      return fromPromise(
        getNewToken().catch(() => {
          localStorage.removeItem('VALENTINE_COUTURE_USER');
          window.location.reload();
          return;
        }),
      ).flatMap((accessToken): any => {
        if (accessToken && accessToken.length > 0) {
          setLocalValue('authToken', accessToken);
        }
        return forward(operation);
      });
    }
  }
});
