import React from 'react';

import { ThemeLink } from '../shared/ThemeLink/ThemeLink';

import './BlogItem.scss';

interface Props {
  slug: string;
  title: string;
  image: string;
  big?: boolean;
}

export const BlogItem = (props: Props) => {
  return (
    <div className={`BlogItem${props.big ? ' BlogItem--big' : ''}`}>
      <ThemeLink to={`/blog/${props.slug}`}>
        <span className='BlogItem__image'>
          <img src={props.image} alt={props.title} />
        </span>
        <h4 className='BlogItem__title'>
          <span>{props.title}</span>
        </h4>
      </ThemeLink>
    </div>
  );
};
