import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formInputs, fields } from '../../../lib/translation/strings';
import { CheckoutFormik } from '../../../lib/formik/useCheckoutFormik';

import './BillingFields.scss';
import { SelectCountry } from '../../shared/SelectCountry/SelectCountry';
import { SelectState } from '../../shared/SelectState/SelectState';

interface Props {
  formik: CheckoutFormik;
  isAuthenticated: boolean;
}

export const BillingFields = (props: Props) => {
  const { t } = useTranslation();
  const formik = props.formik;

  return (
    <>
      <Row>
        <Col xs='12' sm='12'>
          <div className='Checkout__address-actions'>
            <h4 className='Checkout__address-actions__title'>
              <span>1</span>
              {t(fields.personalInformation)}
            </h4>
          </div>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingFirstName'>{t(formInputs.name)}</Label>
            <Input
              type='text'
              id='billingFirstName'
              name='billing.firstName'
              value={formik.values.billing.firstName}
              onChange={formik.handleChange}
              className='form-control'
              invalid={!!formik.errors.billing?.firstName && formik.touched.billing?.firstName}
            />
            <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingLastName'>{t(formInputs.lastName)}</Label>
            <Input
              type='text'
              id='billingLastName'
              name='billing.lastName'
              className='form-control'
              onChange={formik.handleChange}
              value={formik.values.billing.lastName}
              invalid={!!formik.errors.billing?.lastName && formik.touched.billing?.lastName}
            />
            <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='email'>{t(formInputs.email)}</Label>
            <Input
              type='email'
              id='email'
              name='email'
              className='form-control'
              value={formik.values.email}
              onChange={formik.handleChange}
              invalid={!!formik.errors.email && formik.touched.email}
            />
            <FormFeedback>{formik.errors.email}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingPhone'>{t(formInputs.phoneNumber)}</Label>
            <Input
              type='text'
              id='billingPhone'
              name='billing.phone'
              value={formik.values.billing.phone}
              onChange={formik.handleChange}
              className='form-control'
              invalid={!!formik.errors.billing?.phone && formik.touched.billing?.phone}
            />
            <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='12'>
          <FormGroup className='form-group'>
            <Label for='billingAddress'>{t(formInputs.address)}</Label>
            <Input
              type='text'
              id='billingAddress'
              name='billing.address1'
              className='form-control'
              onChange={formik.handleChange}
              value={formik.values.billing.address1}
              invalid={!!formik.errors.billing?.address1 && formik.touched.billing?.address1}
            />
            <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
          </FormGroup>
        </Col>
        {!props.isAuthenticated && (
          <>
            {formik.values.createAccount && (
              <>
                <Col xs='12' md='12'>
                  <FormGroup className='form-group'>
                    <Label for='password'>{t(formInputs.password)}</Label>
                    <Input
                      id='password'
                      type='password'
                      name='password'
                      className='form-control'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      invalid={!!formik.errors.password && formik.touched.password}
                    />
                    <FormFeedback>{formik.errors.password}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs='12' md='12'>
                  <FormGroup className='form-group'>
                    <Label for='passwordConfirm'>{t(formInputs.confirmPassword)}</Label>
                    <Input
                      type='password'
                      id='passwordConfirm'
                      name='passwordConfirm'
                      className='form-control'
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirm}
                      invalid={!!formik.errors.passwordConfirm && formik.touched.passwordConfirm}
                    />
                    <FormFeedback>{formik.errors.passwordConfirm}</FormFeedback>
                  </FormGroup>
                </Col>
              </>
            )}
          </>
        )}
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingCity'>{t(fields.city)}</Label>
            <Input
              type='text'
              id='billingCity'
              name='billing.city'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.billing.city}
              invalid={!!formik.errors.billing?.city && formik.touched.billing?.city}
            />
            <FormFeedback>{formik.errors.billing?.city}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingPostcode'>{t(fields.zipCode)}</Label>
            <Input
              type='text'
              id='billingPostcode'
              name='billing.postcode'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.billing.postcode}
              invalid={!!formik.errors.billing?.postcode && formik.touched.billing?.postcode}
            />
            <FormFeedback>{formik.errors.billing?.postcode}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingState'>{t(fields.state)}</Label>
            <SelectState
              country={formik.values.billing.country}
              name='billing.state'
              initialValue={formik.values.billing.state}
              hasError={!!formik.errors.billing?.state && formik.touched.billing?.state}
              errorMessage={formik.errors.billing?.state}
              onSelect={(state: string) => {
                formik.setFieldValue('billing', {
                  ...formik.values.billing,
                  state,
                });
                formik.setFieldTouched('billing');
              }}
              onComplete={() => {
                return;
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='billingCountry'>{t(fields.country)}</Label>
            <SelectCountry
              name='billing.country'
              initialValue={formik.values.billing.country}
              hasError={!!formik.errors.billing?.country && formik.touched.billing?.country}
              errorMessage={formik.errors.billing?.country}
              onSelect={(country: string) => {
                formik.setFieldValue('billing', {
                  ...formik.values.billing,
                  country,
                });
                formik.setFieldTouched('billing');
              }}
              onComplete={() => {
                return;
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
