import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import { MenuLoader } from './MenuLoader';
import { useRouter } from '../../../lib/hooks/useRouter';
import { useFetchMenu } from '../../../lib/hooks/useFetchMenu';
import { splitArray } from '../../../lib/helpers/splitArray';
import { useInsideClickDetection } from '../../../lib/hooks/useInsideClickDetection';
import { ApolloErrorGuard } from '../../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../../components/shared/HandleLoadingState/HandleLoadingState';
import './Menu.scss';

export const Menu = () => {
  const router = useRouter();
  const ref = useRef(null);
  const { categories, loading, error } = useFetchMenu();
  const [submenu, setSubmenu] = useState<string | undefined>(undefined);

  useInsideClickDetection(ref, () => closeSubmenu());

  useEffect(() => {
    closeSubmenu();
  }, [router.location.pathname]);

  const handleMenuItemClick = (slug: string, hasChildren?: boolean) => {
    if (!hasChildren) {
      router.push(`/shop/${slug}`);
      return;
    }
    if (slug === submenu) {
      closeSubmenu();
      return;
    }
    document.querySelector('.TopHeader')?.classList.add('TopHeader--active');
    setSubmenu(slug);
  };

  const closeSubmenu = () => {
    document.querySelector('.TopHeader')?.classList.remove('TopHeader--active');
    setSubmenu(undefined);
  };

  const [firstTwoElements, restOfElements] = splitArray(
    categories.find(({ slug }) => slug === submenu)?.children?.nodes,
    2,
  );

  return (
    <div className='Menu'>
      <Container>
        <div className='Menu-items'>
          {categories?.map(({ id, name, slug, children }) => (
            <div
              key={id}
              role='button'
              className='Menu__item'
              onClick={() => handleMenuItemClick(slug, children?.nodes?.length > 0)}
            >
              <span className='Menu__item_inner'>{name}</span>
            </div>
          ))}
        </div>
      </Container>

      {!!submenu && (
        <>
          <div ref={ref} className='Menu-dropdown__shadow'></div>
          <div className='Menu-dropdown'>
            <Container>
              <HandleLoadingState loading={loading} loadingPlaceholder={<MenuLoader />}>
                <ApolloErrorGuard error={error}>
                  <div className='Menu-dropdown-wrapper'>
                    <div className='Menu-dropdown-left'>
                      {restOfElements?.length > 0 && <h6>Collections</h6>}
                      <ul className='Menu-dropdown__list'>
                        {restOfElements?.map((category) => (
                          <li key={category.id} className='Menu-dropdown__list-item'>
                            <NavLink
                              to={`/shop/${submenu}/${category.slug}`}
                              className='Menu-dropdown__list-item-title'
                            >
                              {category?.name.toLocaleLowerCase()}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='Menu-dropdown-right'>
                      {firstTwoElements?.map((category) => (
                        <NavLink
                          key={category.id}
                          to={`/shop/${submenu}/${category.slug}`}
                          className='Menu-dropdown__box'
                        >
                          {category?.image?.sourceUrl && (
                            <div className='Menu-dropdown__box-image'>
                              <img src={category?.image?.sourceUrl} alt={category?.name} />
                            </div>
                          )}
                          <div className='Menu-dropdown__box-content'>
                            <h5>{category?.name.toLocaleLowerCase()}</h5>
                            <span>Discover more</span>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </ApolloErrorGuard>
              </HandleLoadingState>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};
