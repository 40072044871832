import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useAuthContext } from '../../context/AuthContext/AuthContext';

import { formValidation } from '../../translation/strings';
import { ProfileAccountFields } from '../../../components/ProfilePages/ProfileAccount/ProfileAccount';

interface UseProfileAccountOptions {
  initialValues?: ProfileAccountFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProfileAccountFields>
  ) => Promise<any>;
}

export const useProfileAccountFormik = (opts: UseProfileAccountOptions) => {
  const {t} = useTranslation();
  const authCtx = useAuthContext();


  const ProfileAccountSchema = Yup.object().shape({
    firstName: Yup.string().required(t(formValidation.firstName)),
    lastName: Yup.string().required(formValidation.lastName),
  });
  

  return useFormik({
    initialValues: {
      email: authCtx.user?.email || '',
      lastName: authCtx.user?.lastName || '',
      username: authCtx.user?.username || '',
      firstName: authCtx.user?.firstName || '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfileAccountSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileAccountFormik = ReturnType<typeof useProfileAccountFormik>;
