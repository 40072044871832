import React from 'react';

import './LoadingIcon.scss';

export const LoadingIcon = () => {
  return (
    <div className='loading-icon'>
      <svg
        version='1.1'
        id='L9'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'
        enableBackground='new 0 0 0 0'
      >
        <defs>
          <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' style={{ stopColor: '#000', stopOpacity: 1 }} />
            <stop offset='50%' style={{ stopColor: '#333', stopOpacity: 1 }} />
            <stop offset='75%' style={{ stopColor: '#666', stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: '#999', stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        <circle
          cx='50'
          cy='50'
          r='17'
          fill='none'
          stroke='url(#gradient)'
          strokeWidth='3'
          strokeLinecap='round'
        >
          <animateTransform
            attributeName='transform'
            attributeType='XML'
            type='rotate'
            dur='1s'
            from='0 50 50'
            to='360 50 50'
            repeatCount='indefinite'
          />
        </circle>

        <g transform='translate(41.5, 44)'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.66 0L16.81 0.01C15.42 2.4 14.03 4.79 12.64 7.17C12.58 7 12.36 6.43 11.74 6.06C11 5.62 10.26 5.79 10.12 5.83C11.3 3.89 12.48 1.95 13.66 0Z'
              fill='#000'
            ></path>
            <path
              d='M0 0.110001C1.05 0.110001 2.09 0.120001 3.14 0.120001C4.33 2.05 5.52 3.98 6.72 5.91C6.55 5.87 5.95 5.74 5.32 6.05C4.51 6.44 4.27 7.22 4.23 7.35C2.82 4.94 1.41 2.53 0 0.110001Z'
              fill='#000'
            ></path>
            <path
              d='M8.44 8.62C8.51 8.44 8.62 8.18 8.8 7.9C9.07 7.47 9.32 7.07 9.82 6.86C9.99 6.79 10.59 6.56 11.19 6.88C11.65 7.12 11.84 7.54 11.88 7.66C12.18 8.43 11.72 9.13 11.68 9.2C10.65 11.06 9.62 12.92 8.58 14.78C7.45 12.96 6.31 11.15 5.18 9.33C4.81 8.8 4.77 8.13 5.06 7.58C5.44 6.88 6.2 6.76 6.29 6.75C6.45 6.73 6.69 6.72 6.95 6.82C7.4 6.98 7.65 7.34 7.98 7.82C8.11 8.01 8.28 8.28 8.44 8.62Z'
              fill='#000'
            ></path>
          </svg>
        </g>
      </svg>
    </div>
  );
};
