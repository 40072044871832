import React from 'react';
import { ShopSelectOptions } from './ShopSelect';

import './ShopSelect.scss';

interface Props {
  selected?: ShopSelectOptions[];
  options?: ShopSelectOptions[];
  handleOnItemSelect: (option: ShopSelectOptions) => void;
}

export const ShopSelectOptionsList = (props: Props) => {
  return (
    <ul className='ShopSelect__options_list'>
      {props.options?.map((option, index) => {
        const items = props.selected;

        const target =
          items && Array.isArray(items)
            ? items.find((o) => {
                return o.value === option.value;
              })
            : null;

        return (
          <li
            key={`${option.value}-${index}`}
            data-selected={target?.value === option.value}
            className='ShopSelect__options_list_item'
            onClick={() => props.handleOnItemSelect(option)}
          >
            {option.label}
          </li>
        );
      })}
    </ul>
  );
};
