import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input, Form, FormFeedback } from 'reactstrap';

import { useRouter } from '../../lib/hooks/useRouter';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { useResetPasswordFormik } from '../../lib/formik/useResetPasswordFormik';
import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';

import { actions, messages } from '../../lib/translation/strings';

import { EmptyPage } from '../../components/shared/EmptyMessage/EmptyPage';
import { ThemeContainer } from '../../components/shared/ThemeContainer/ThemeContainer';
import { Button } from '../../components/shared/Button/Button';

import './ResetPassword.scss';

export interface ResetPasswordFields {
  key: string;
  login: string;
  password: string;
  passwordConfirm: string;
}

export const ResetPassword = () => {
  const router = useRouter();
  const authCtx = useAuthContext();
  const { t } = useTranslation();
  const notification = useNotification();
  const [state, setState] = useState({
    loading: false,
    success: false,
  });

  const formik = useResetPasswordFormik({
    initialValues: {
      key: `${router.query.key}`,
      login: `${router.query.login}`,
      password: '',
      passwordConfirm: '',
    },
    onSubmit: async (values) => {
      notification.removeAll();
      setState({
        ...state,
        loading: true,
      });
      try {
        authCtx.resetPassword({
          key: values.key,
          login: values.login,
          password: values.password,
        });

        setState({
          ...state,
          success: true,
          loading: false,
        });
      } catch (e) {
        notification.danger((e as Error).message);
        setState({
          ...state,
          loading: false,
        });
      }
    },
  });

  return (
    <div className='ResetPassword'>
      <ThemeContainer>
        {state.success ? (
          <EmptyPage
            redirectLink='login'
            title={t(actions.approve)}
            buttonText={t(actions.login)}
            description={t(messages.resetPasswordSuccess)}
          />
        ) : (
          <>
            <h4>New Password</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs='12' sm='6'>
                  <FormGroup>
                    <Input
                      type='password'
                      id='password'
                      placeholder='Password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className='form-control-custom'
                      invalid={!!formik.errors.password && formik.touched.password}
                    />
                    <FormFeedback>{formik.errors.password}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs='12' sm='6'>
                  <FormGroup>
                    <Input
                      type='password'
                      id='passwordConfirm'
                      placeholder='Password Confirm'
                      onChange={formik.handleChange}
                      className='form-control-custom'
                      value={formik.values.passwordConfirm}
                      invalid={!!formik.errors.passwordConfirm && formik.touched.passwordConfirm}
                    />
                    <FormFeedback>{formik.errors.passwordConfirm}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs='12'>
                  <FormGroup>
                    <Button
                      type='submit'
                      disabled={state.loading}
                      onClick={() => formik.submitForm()}
                      className='w-100-mobile reset-btn'
                    >
                      {state.loading ? t(actions.processing) : t(actions.send)}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </ThemeContainer>
    </div>
  );
};
