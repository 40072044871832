import React, { useRef } from 'react';
import { debounce } from 'lodash';
import cs from 'classnames';
import { useCartContext } from '../../../lib/context/CartContext/CartContext';
import { Icon } from '../../../components/shared/Icon/Icon';
import { useMenuSidebar } from '../../../lib/context/MenuSidebarContext/MenuSidebarContext';
import './UserCart.scss';

interface Props {
  className?: string;
}
export const UserCart = (props: Props) => {
  const ref = useRef<any>(null);
  const cartCtx = useCartContext();
  const menuSidebar = useMenuSidebar();

  const onMouseEnter = debounce(() => {
    if (!cartCtx.isEmpty) {
      cartCtx.setCartUiState({ ...cartCtx.cartUiState, open: true });
      if (ref && ref.current) {
        ref.current.setAttribute('force-open', true);
      }
    }
  }, 300);

  const onMouseLeave = debounce(() => {
    if (!cartCtx.isEmpty) {
      cartCtx.setCartUiState({
        ...cartCtx.cartUiState,
        open: false,
      });
      if (ref && ref.current) {
        ref.current.removeAttribute('force-open');
      }
    }
  }, 300);

  const count = cartCtx?.itemCount;

  return (
    <span ref={ref}>
      <span
        role='button'
        onClick={() => menuSidebar.openSidebar('cart')}
        id='user-cart'
        className={cs('UserCart', props.className)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span className='UserNavigation__item--wishlist-icon'>
          {count && count > 0 ? <span className='UserCart__count'>{count}</span> : null}
          <Icon icon='shopping-bag' />
        </span>
      </span>
    </span>
  );
};
