import React from 'react';

interface Props {
  hasItems: boolean;
  placeholder: JSX.Element;
  children: React.ReactNode;
}

export const HandleNoItemsState = (props: Props) => {
  if (!props.hasItems) {
    return <>{props.placeholder}</>;
  }
  return <>{props.children}</>;
};
