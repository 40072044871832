import React, { Fragment } from 'react';
import shortid from 'shortid';

interface Props {
  length: number;
  children: React.JSX.Element | React.JSX.Element;
}

export const BulkLoader = (props: Props) => {
  const items = Array.from({ length: props.length }, (v, i) => {
    return { key: shortid.generate(), value: i + 1 };
  });

  return (
    <>
      {items.map((item) => (
        <Fragment key={`${item.key}-loader`}>{props.children}</Fragment>
      ))}
    </>
  );
};
