import React from 'react';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'reactstrap';

import { useProfileAddressFormik } from '../../../lib/formik/profile/useProfileAddressFormik';

import { ProfileAddressFields } from './ProfileAddress';
import { ProfileAddressBilling } from './ProfileAddressBilling';
import { ProfileAddressShipping } from './ProfileAddressShipping';
import { BlockButton } from '../../shared/BlockButton/BlockButton';
import { actions } from '../../../lib/translation/strings';

interface Props {
  loading: boolean;
  customer: ProfileAddressFields;
  onSubmit: (values: FormikValues) => void;
}

export const ProfileAddressForm = (props: Props) => {
  const { t } = useTranslation();
  const { billing, shipping } = props.customer;

  const formik = useProfileAddressFormik({
    initialValues: {
      billing: {
        firstName: billing.firstName,
        lastName: billing.lastName,
        city: billing.city,
        postcode: billing.postcode,
        state: billing.state,
        country: billing.country,
        address1: billing.address1,
        phone: billing.phone,
      },
      shipping: {
        firstName: shipping.firstName,
        lastName: shipping.lastName,
        city: shipping.city,
        postcode: shipping.postcode,
        state: shipping.state,
        country: shipping.country,
        address1: shipping.address1,
      },
    },
    onSubmit: async (values) => {
      if (!formik.dirty) {
        return;
      }

      props.onSubmit(values);
    },
  });

  return (
    <div className='ProfileAddressForm'>
      <ProfileAddressBilling formik={formik} />

      <ProfileAddressShipping formik={formik} />

      <FormGroup>
        <BlockButton
          type='submit'
          className='w-100-mobile'
          onClick={formik.submitForm}
          disabled={!formik.dirty || props.loading}
        >
          {props.loading ? t(actions.saving) : t(actions.save)}
        </BlockButton>
      </FormGroup>
    </div>
  );
};
