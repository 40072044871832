import { ReactNode } from 'react';
import { ShopProductCategory } from '../queries/shop';

export interface BlogProps {
  id: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  excerpt: string;
  slug: string;
  title: string;
}

export interface BlogQueryProps {
  posts: {
    nodes: Array<BlogProps>;
    pageInfo: {
      endCursor: string;
      hasNextPage: string;
    };
  };
}

export interface PageInfo {
  endCursor?: string;
  startCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

export enum ProductTaxonomyEnum {
  PAWEAR_TYPE = 'PA_WEAR_TYPE',
  PAOCCASION = 'PA_OCCASION',
  PASIZE = 'PA_SIZE',
  PACOLOR = 'PA_COLOR',
  PRODUCTCATEGORY = 'PRODUCT_CAT',
  //old onces
  PRODUCTTAG = 'PRODUCT_TAG',
  PRODUCTTYPE = 'PRODUCT_TYPE',
  SHIPPINGCLASS = 'SHIPPING_CLASS',
  VENDOR = 'VENDOR',
  VISIBLEPRODUCT = 'VISIBLE_PRODUCT',
}

export enum TaxonomyOperatorEnum {
  AND = 'AND',
  EXISTS = 'EXISTS',
  IN = 'IN',
  NOT_EXISTS = 'NOT_EXISTS',
  NOT_IN = 'NOT_IN',
}

export interface ProductTaxonomyFilterInput {
  taxonomy: ProductTaxonomyEnum;
  terms: string | string[];
  operator?: TaxonomyOperatorEnum;
}

export interface Coupon {
  code: string;
  discountAmount: string;
  description: string;
  discountTax: string;
}

export interface AttributeTerms {
  count: number;
  id: string;
  name: string;
  slug: string;
  image: string | null;
}

export interface AttributeFilter {
  id: string;
  name: string;
  slug: string;
  label: string;
  terms: AttributeTerms[];
}

export interface ChosenAttributesInput extends ProductTaxonomyFilterInput {}

export interface ShopMobileFiltersItem {
  id: string;
  slug: string;
  label: string | ReactNode;
  type?: 'category' | 'attribute';
  children: ShopMobileFiltersItem[] | null;
  data?: ShopProductCategory | AttributeFilter;
}
