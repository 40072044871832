import React from 'react';
import ContentLoader from 'react-content-loader';

export const ShopSidebarPlaceholder = () => {
  return (
    <ContentLoader
      speed={2}
      height={50}
      style={{ maxWidth: '100%' }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="ShopSidebarPlaceholder"
    >
      <rect x="0" y="0" width="300" height="40" />
    </ContentLoader>
  );
};
