import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { UserCart } from './UserCart';
import { ThemeLink } from '../../../components/shared/ThemeLink/ThemeLink';
import { Icon } from '../../../components/shared/Icon/Icon';
import { Search } from '../Search/Search';
import { useRouter } from '../../../lib/hooks/useRouter';
import { useMenuContext } from '../../../lib/context/MenuContext/MenuContext';
import { useMenuSidebar } from '../../../lib/context/MenuSidebarContext/MenuSidebarContext';
import { useWishlist } from '../../../lib/hooks/useWishlist';

import './UserNavigation.scss';

export const UserNavigation = () => {
  const router = useRouter();
  const menuCtx = useMenuContext();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();
  const { getProductsIDs } = useWishlist();
  const wishlistCount = getProductsIDs()?.length || 0;

  useEffect(() => {
    setIsSearchOpen(false);
  }, [router.location]);

  return (
    <div className='UserNavigation d-flex'>
      {isSearchOpen && <Search onClose={() => setIsSearchOpen(false)} />}
      <span
        role='button'
        className='UserNavigation__item UserNavigation__item--search'
        onClick={() => setIsSearchOpen(!isSearchOpen)}
      >
        <span role='button'>
          <Icon icon='search' />
        </span>
      </span>

      <div className='UserNavigation__item UserNavigation__item--login'>
        {authCtx.isAuthenticated ? (
          <ThemeLink to='/my-profile' activeClassName='UserNavigation__item--active'>
            <span className='UserNavigation__item--wishlist-icon'>
              <Icon icon='user' />
            </span>
          </ThemeLink>
        ) : (
          <ThemeLink to='/login' activeClassName='UserNavigation__item--active'>
            <span className='UserNavigation__item--wishlist-icon'>
              <Icon icon='user' />
            </span>
          </ThemeLink>
        )}
      </div>

      <span
        role='button'
        onClick={() => menuSidebar.openSidebar('wishlist')}
        className='UserNavigation__item UserNavigation__item--wishlist'
      >
        <span className='UserNavigation__item--wishlist-icon'>
          {wishlistCount && wishlistCount > 0 ? (
            <span className='UserWishlist__count'>{wishlistCount}</span>
          ) : null}
          <Icon icon='heart' />
        </span>
      </span>

      <UserCart className='UserNavigation__item' />

      <span role='button' className='UserNavigation__hamburger' onClick={menuCtx.openSidebar}>
        <Icon icon='menu' />
      </span>
    </div>
  );
};
