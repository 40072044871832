import gql from 'graphql-tag';

export type StatesResponse = {
  countryStates: {
    code: string;
    name: string;
  }[];
};

export const GET_STATES = gql`
  query getStates($country: CountriesEnum!) {
    countryStates(country: $country) {
      code
      name
    }
  }
`;

export type CountriesResponse = {
  allowedCountries: string[];
};

export const GET_COUNTRIES = gql`
  query getCountries {
    allowedCountries
  }
`;

export interface FooterOptionsResponse {
  generalOption: {
    generalOptions: {
      footer: {
        address: string;
        email: string;
        phone: string;
        facebook?: string;
        instagram?: string;
        twitter?: string;
        youtube?: string;
        tiktok?: string;
        pinterest?: string;
        menus: {
          title: string;
          menu: {
            name: string;
            link: string;
          }[];
        }[];
      };
    };
  };
}

export const GET_FOOTER_OPTIONS = gql`
  query getFooterOptions {
    generalOption {
      generalOptions {
        footer {
          address
          email
          facebook
          instagram
          phone
          twitter
          youtube
          tiktok
          pinterest
          menus {
            title
            menu {
              name
              link
            }
          }
        }
      }
    }
  }
`;

export interface FAQItem {
  title: string;
  description: string;
}

export interface FAQResponse {
  generalOptions: {
    generalOptions: {
      faq: {
        list: FAQItem[];
      };
    };
  };
}

export const GET_FAQ = gql`
  query FAQQuery {
    generalOptions {
      generalOptions {
        faq {
          list {
            title
            description
          }
        }
      }
    }
  }
`;

export type GetPageBySlugResponse = {
  pageBy: {
    title: string;
    id: string;
    content: string;
    featuredImage: {
      node: {
        sourceUrl: string;
      };
    };
  };
};

export const GET_PAGE_BY_SLUG = gql`
  query getPageBySlug($slug: String) {
    pageBy(uri: $slug) {
      title
      id
      content(format: RENDERED)
      featuredImage {
        node {
          sourceUrl(size: LARGE)
        }
      }
    }
  }
`;
