import React from 'react';
import classNames from 'classnames';
import './DefaultButton.scss';
import { Button } from '../Button/Button';

interface Props {
  to?: string;
  title: string;
  isFull?: boolean;
  className?: string;
  onClick?: () => void;
}

export const DefaultButton = (props: Props) => {
  const cs = classNames('DefaultButton full-mob', {
    'DefaultButton--full': props.isFull
  });

  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={`${cs} ${props.className ? props.className : ''}`}>
      <Button onClick={handleOnClick} to={props.to}>
        {props.title}
      </Button>
    </div>
  );
};
