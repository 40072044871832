import React from 'react';
import { useRegisterFormik } from '../../lib/formik/useRegisterFormik';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { RegisterForm } from './RegisterForm';

import './Register.scss';

export interface RegisterFields {
  registerName: string;
  registerLastName: string;
  registerEmail: string;
  registerTelephone: string;
  registerAddress: string;
  registerCity: string;
  registerPassword: string;
  registerConfirmPassword: string;
}

export const Register = () => {
  const authCtx = useAuthContext();

  const formik = useRegisterFormik({
    onSubmit: async (values) => {
      authCtx.register({
        firstname: values.registerName,
        lastname: values.registerLastName,
        municipality: values.registerCity,
        address: values.registerAddress,
        password: values.registerPassword,
        telephone: values.registerTelephone,
        email: values.registerEmail,
      });
    },
  });

  return (
    <div className='Register'>
      <div className='Register__wrapper'>
        <h4>Register</h4>
        <RegisterForm formik={formik} loading={authCtx.isRegistering} />
      </div>
    </div>
  );
};
