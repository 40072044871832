import React from 'react';
import cs from 'classnames';

import { Spinner } from 'reactstrap';
import { errors } from '../../../../lib/translation/strings';
import { useTranslation } from 'react-i18next';
import './ShopSelect.scss';
import { ProductTaxonomyEnum } from '../../../../types/generalTypes';
import { HandleNoItemsState } from '../../../shared/HandleNoItemsState/HandleNoItemsState';
import { ShopSelectOptionsList } from './ShopSelectOptionsList';
import { ShopSelectOptionsColor } from './ShopSelectOptionsColor';
import { ShopSelectOptionsSize } from './ShopSelectOptionsSize';

export interface ShopSelectOptions {
  value: string;
  label: string | React.ReactNode;
}

interface Props {
  attribute: ProductTaxonomyEnum;
  label: string;
  className?: string;
  loading?: boolean;
  disableMultiselect?: boolean;
  hideIfNoOptions?: boolean;
  selected?: ShopSelectOptions[];
  options?: ShopSelectOptions[];
  onSelect: (option?: ShopSelectOptions) => void;
}

export const ShopSelect = (props: Props) => {
  const { t } = useTranslation();

  const handleOnSelect = (item?: ShopSelectOptions) => {
    props.onSelect(item);
  };

  const handleOnItemSelect = (option: ShopSelectOptions) => {
    if (props.selected?.includes(option) && props.disableMultiselect) {
      handleOnSelect(undefined);
      return;
    }

    handleOnSelect(option);
  };

  const renderOptions = () => {
    if (props.attribute === ProductTaxonomyEnum.PACOLOR) {
      return (
        <ShopSelectOptionsColor
          options={props.options}
          selected={props.selected}
          handleOnItemSelect={handleOnItemSelect}
        />
      );
    }
    if (props.attribute === ProductTaxonomyEnum.PASIZE) {
      return (
        <ShopSelectOptionsSize
          options={props.options}
          selected={props.selected}
          handleOnItemSelect={handleOnItemSelect}
        />
      );
    }
    return (
      <ShopSelectOptionsList
        options={props.options}
        selected={props.selected}
        handleOnItemSelect={handleOnItemSelect}
      />
    );
  };

  if (props.hideIfNoOptions && props.options && props.options.length <= 0) {
    return null;
  }

  return (
    <div
      className={cs(
        'ShopSelect',
        props.loading && 'ShopSelect--loading',
        props.selected && props.selected.length > 0 && 'ShopSelect--has-selected',
        props.className,
      )}
    >
      <h4 className='ShopSelect__label'>
        {props.label}
        {props.loading && (
          <span className='ShopSelect__label_icon'>
            <Spinner className='ShopSelect__label_icon_spinner' />
          </span>
        )}
      </h4>

      <div className='ShopSelect__options'>
        <HandleNoItemsState
          placeholder={<span>{t(errors.noOptions)}</span>}
          hasItems={!!props.options && props.options.length > 0}
        >
          {renderOptions()}
        </HandleNoItemsState>
      </div>
    </div>
  );
};
