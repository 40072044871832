import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import './CheckoutConfirmation.scss';

export const CheckoutFail = () => {
  return (
    <div className='CheckoutConfirmation'>
      <Container>
        <div className='CheckoutConfirmation__head'>
          <h1 className='failed-title'>Order failed!</h1>
          <h3 className='failed-subtitle'>We were unable to process your payment.</h3>

          <div className='content'>
            <p>Please try again or contact your bank for further details.</p>
            <p>
              If you continue to experience issues contact Valentine Couture support{' '}
              <Link to='/contact'>here</Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};
