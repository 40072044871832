import React from 'react';
import cs from 'classnames';
import { useShopContext } from '../../../../lib/context/ShopContext/ShopContext';

import './SortSelect.scss';

interface Props {
  className?: string;
}

const SORT_OPTIONS = [
  { value: '', label: 'Sort' },
  { value: 'PRICE-DESC', label: 'Price highest' },
  { value: 'PRICE-ASC', label: 'Price lowest' },
  { value: 'SLUG-ASC', label: 'Alphabet A-Z' },
  { value: 'SLUG-DESC', label: 'Alphabet Z-A' },
];

export const SortSelect = ({ className }: Props) => {
  const shopCtx = useShopContext();

  const hanleMobileSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (shopCtx.handlers?.handleOrderChange) {
      shopCtx.handlers.handleOrderChange(e.target.value);
    }
  };

  return (
    <div className={cs('SortSelect', className)}>
      <select
        name='products-order'
        value={shopCtx?.data?.order || ''}
        onChange={hanleMobileSelectChange}
      >
        {SORT_OPTIONS.map((o, i) => (
          <option key={`${o.value}-${i}`} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};
