import React from 'react';
import { useScrollToTop } from './lib/hooks/useScrollToTop';
import { useAuthContext } from './lib/context/AuthContext/AuthContext';
import { Partytown } from '@builder.io/partytown/react';
import { NotificationContextProvider } from './lib/context/NotificationContext/NotificationContextProvider';
import { Routes } from './routes/Routes';
import { Header } from './layout/Header/Header';
import { Footer } from './layout/Footer/Footer';
import { LoadingPlaceholder } from './components/shared/LoadingPlaceholder/LoadingPlaceholder';
import { useMenuSidebar } from './lib/context/MenuSidebarContext/MenuSidebarContext';
import { WishlistSidebar } from './layout/WishlistSidebar/WishlistSidebar';
import { CartSidebar } from './layout/CartSidebar/CartSidebar';
import { LoadingIcon } from './components/shared/LoadingIcon/LoadingIcon';

import './App.scss';

export const App = () => {
  useScrollToTop();
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();

  const handleOnClick = () => {
    menuSidebar.closeSidebar('wishlist');
    menuSidebar.closeSidebar('cart');
  };

  const isLoading = authCtx.isLoading || authCtx.isLoadingData;
  const isShopPage = location.pathname.startsWith('/shop');

  return (
    <div className={`App ${isLoading ? 'App--loading' : ''}`} id='App'>
      {process.env.REACT_APP_ENV === 'development' && (
        <>
          <Partytown debug={true} forward={['dataLayer.push']} />
          <script
            async
            type='text/partytown'
            src='https://www.googletagmanager.com/gtag/js?id=G-PV8CR74H2E '
          />
          <script
            type='text/partytown'
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-PV8CR74H2E ');
            `,
            }}
          />
        </>
      )}
      {isLoading && <LoadingPlaceholder loadingPlaceholder={<LoadingIcon />} />}
      <div className='App__backdrop' onClick={handleOnClick} />
      <Header />
      <div className={`App__main_content ${isShopPage ? 'no-padding' : ''}`}>
        <NotificationContextProvider>
          <Routes />
        </NotificationContextProvider>
      </div>
      <Footer />

      <WishlistSidebar />
      <CartSidebar />
    </div>
  );
};
