import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import { useRouter } from '../../hooks/useRouter';
import { useAuthContext } from '../AuthContext/AuthContext';
import { useCouponMutations } from '../../hooks/useCouponMutations';

import { CartProduct } from '../../../types/cartTypes';
import { CartContext, CartContextType, CartUiState } from './CartContext';
import * as cartMutations from '../../../queries/mutations/cartMutations';
import * as cartQueries from '../../../queries/cartQueries';
import { ProductSingleVariationProps } from '../../../queries/archive';
import { actions } from '../../translation/strings';
import { LoadingPlaceholder } from '../../../components/shared/LoadingPlaceholder/LoadingPlaceholder';
import { logError } from '../../helpers/logError';

interface CartContextProviderProps {
  children: React.ReactNode | null;
}

export const CartContextProvider = (props: CartContextProviderProps) => {
  const router = useRouter();
  const auth = useAuthContext();
  const { t } = useTranslation();
  const coupons = useCouponMutations();

  const [cartUiState, setCartUiState] = useState<CartUiState>({
    open: false,
    productAdded: false,
  });

  const {
    loading,
    error,
    data: cartData,
    refetch,
  } = useQuery<cartQueries.GetCartResponse>(cartQueries.getCart);

  const [updateItemQuantities, { loading: isUpdatingProduct, error: updateErrors }] = useMutation(
    cartMutations.UPDATE_ITEM_QUANTITIES,
  );

  const [addItem, { loading: isAddingProduct, error: addErrors }] = useMutation(
    cartMutations.ADD_ITEM_TO_CART,
  );

  const [updateShipping, { loading: isUpdatingShippingMethod }] = useMutation<
    cartMutations.UpdateShippingMethodResponse,
    cartMutations.UpdateShippingMethodVariables
  >(cartMutations.updateShippingMethod);

  const refetchCart = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetchCart();
  }, [auth.isAuthenticated, refetchCart]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetCartUiState();
    }, 2300);

    return () => {
      clearTimeout(timeout);
    };
  }, [cartUiState.open]);

  useEffect(() => {
    resetCartUiState();
  }, [router.location.pathname]);

  const resetCartUiState = () => {
    const userCart = document.getElementById('user-cart');
    const hasForceOpen = userCart && !!userCart.getAttribute('force-open');
    setCartUiState((prevState) => ({
      ...prevState,
      open: !!hasForceOpen,
      productAdded: false,
    }));
  };

  const addCartUiState = () => {
    setCartUiState({
      ...cartUiState,
      open: true,
      productAdded: true,
    });
  };

  const updateProductQuantity = (productKey: string, value: number) => {
    try {
      return updateItemQuantities({
        variables: {
          input: {
            clientMutationId: 'cart-quantity-update',
            items: [{ key: productKey, quantity: value }],
          },
        },
        update(cache, { data: { updateItemQuantities: updateData } }) {
          const newItems =
            value === 0
              ? cartData!.cart.contents.nodes.filter((item: CartProduct) => {
                  return item.key !== updateData.items[0].key;
                })
              : cartData!.cart.contents.nodes.map((item: CartProduct) => {
                  return {
                    ...item,
                    quantity:
                      item.key === updateData.items[0].key
                        ? updateData.items[0].quantity
                        : item.quantity,
                  };
                });

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cartData!.cart,
                isEmpty: updateData.cart.isEmpty,
                subtotal: updateData.cart.subtotal,
                total: updateData.cart.total,
                contents: {
                  ...cartData!.cart.contents,
                  itemCount: updateData?.cart?.contents?.itemCount,
                  nodes: newItems,
                },
              },
            },
          });
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const addProduct = (
    productID: number,
    quantity: number,
    variation: ProductSingleVariationProps | undefined,
    extraData?: { [key: string]: string },
  ) => {
    try {
      return addItem({
        variables: {
          clientMutationId: 'add-product-to-cart',
          productId: productID,
          quantity,
          variationId: variation?.databaseId,
          extraData: JSON.stringify(extraData),
        },
        update(
          cache,
          {
            data: {
              addToCart: { cart },
            },
          },
        ) {
          cache.writeQuery({
            query: cartQueries.getCart,
            data: { cart: cart },
          });

          addCartUiState();
        },
      });
    } catch (error) {
      logError(error);
    }
  };

  const emptyCart = () => {
    try {
      refetchCart();
    } catch (error) {
      logError(error);
    }
  };

  const updateShippingMethod = (shippingMethodId: string[]) => {
    try {
      updateShipping({
        variables: {
          shippingMethods: shippingMethodId,
        },
        update(cache, data) {
          const updatedCartData = data.data?.updateShippingMethod.cart;

          console.log(updatedCartData?.shippingTotal);

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cartData!.cart,
                total: updatedCartData?.total,
                shippingTotal: updatedCartData?.shippingTotal,
                chosenShippingMethods: updatedCartData?.chosenShippingMethods,
              },
            },
          });
        },
      });
    } catch (error) {
      logError(error);
    }
  };

  const applyCoupon = async (coupon: string) => {
    try {
      return coupons.applyCoupon({
        variables: {
          coupon: coupon,
        },
        update(cache, data) {
          const updatedCart = data?.data?.applyCoupon?.cart;
          const total = updatedCart?.total;
          const appliedCoupons = updatedCart?.appliedCoupons ?? [];

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cartData!.cart,
                total: total,
                appliedCoupons: appliedCoupons,
              },
            },
          });
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const removeCoupon = async (coupon: string) => {
    try {
      await coupons.removeCoupon({
        variables: {
          coupon: coupon,
        },
        update(cache, data) {
          const updatedCart = data?.data?.removeCoupons?.cart;
          const appliedCoupons = updatedCart?.appliedCoupons ?? [];
          const total = updatedCart?.total;

          cache.writeQuery({
            query: cartQueries.getCart,
            data: {
              cart: {
                ...cartData!.cart,
                total: total,
                appliedCoupons: appliedCoupons,
              },
            },
          });
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const context: CartContextType = {
    isLoading: loading,
    isAddingProduct: isAddingProduct,
    isUpdatingProduct: isUpdatingProduct,
    isUpdatingShippingMethod: isUpdatingShippingMethod,
    isApplyingCoupon: coupons?.applyCouponRes?.loading,
    isRemovingCoupon: coupons?.removeCouponRes?.loading,

    total: cartData?.cart.total ?? '',
    isEmpty: cartData?.cart.isEmpty ?? false,
    subtotal: cartData?.cart.subtotal ?? '',
    chosenShippingMethods: cartData?.cart?.chosenShippingMethods[0] ?? '',
    shippingTotal: cartData?.cart?.shippingTotal ?? '',
    appliedCoupons: cartData?.cart?.appliedCoupons?.[0] ?? null,
    hasCoupons: !!cartData?.cart?.appliedCoupons?.length,
    itemCount: cartData?.cart?.contents?.itemCount ?? null,
    items: cartData?.cart?.contents?.nodes ?? ([] as cartQueries.CartNode[]),

    error: error || updateErrors || addErrors,
    cartUiState,

    setCartUiState,
    addProduct,
    updateShippingMethod,
    refetch,
    updateProductQuantity,
    applyCoupon,
    removeCoupon,
    emptyCart,
  };

  return (
    <CartContext.Provider value={context}>
      {coupons?.removeCouponRes?.loading && <LoadingPlaceholder text={t(actions.removingCoupon)} />}

      {isUpdatingShippingMethod && <LoadingPlaceholder text={t(actions.updatingShippingMethod)} />}

      {props.children}
    </CartContext.Provider>
  );
};
