import React, { ReactNode } from 'react';
import cs from 'classnames';
import classNames from 'classnames';
// import { Container } from 'reactstrap';
// import { Icon } from '../../shared/Icon/Icon';
import { SortSelect } from './SortSelect/SortSelect';
import { ShopFilterButton } from './ShopFilterButton/ShopFilterButton';

import './ShopLayout.scss';

interface Props {
  sidebar?: ReactNode;
  content: ReactNode;
  className?: string;
}

export const ShopLayout = (props: Props) => {
  return (
    <div className={classNames('ShopLayout', props.className)}>
      {props.sidebar}
      <div className='ShopLayout__header'>
        <ShopFilterButton className={cs('ShopFilterActions__btn ShopFilterActions__filter')} />
        <div className='ShopLayout__header-right'>
          {/* <div className='ShopLayout__header-grid'>
            <Icon
              icon='grid-3'
              className={cs(!isFourColGrid && 'is--active')}
              onClick={() => {
                setIsFourColGrid(false);
              }}
            />
            <Icon
              icon='grid-4'
              className={cs(isFourColGrid && 'is--active')}
              onClick={() => {
                setIsFourColGrid(true);
              }}
            />
          </div> */}

          <SortSelect />
        </div>
      </div>
      <div className={cs('ShopLayout__content', 'ShopLayout__content--four')}>{props.content}</div>
    </div>
  );
};
