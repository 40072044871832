import React from 'react';
import cn from 'classnames';
import { Container } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ShopCatalogBannerLoader } from './ShopCatalogBannerLoader';
import { HandleLoadingState } from '../../../shared/HandleLoadingState/HandleLoadingState';
import { ApolloErrorGuard } from '../../../shared/ApolloErrorGuard/ApolloErrorGuard';
import {
  GET_COLLECTION_BY_SLUG,
  GetProductBySlugResponse,
  GetProductBySlugVariables,
} from '../../../../queries/shop';

import './ShopCatalogBanner.scss';

export const ShopCatalogBanner = () => {
  const params = useParams<{ parentCategory: string; childCategory: string }>();
  const { data, loading, error } = useQuery<GetProductBySlugResponse, GetProductBySlugVariables>(
    GET_COLLECTION_BY_SLUG,
    {
      variables: {
        slug: `${params.childCategory || params.parentCategory}`,
      },
    },
  );

  const collection = data?.terms?.nodes?.[0];

  if (!params.parentCategory || (!collection && !loading)) return null;

  return (
    <div className='ShopCatalogBanner'>
      <Container>
        <HandleLoadingState loading={loading} loadingPlaceholder={<ShopCatalogBannerLoader />}>
          <ApolloErrorGuard error={error}>
            <div
              className={cn(
                'ShopCatalogBanner__wrapper',
                !collection?.image?.sourceUrl && 'ShopCatalogBanner__wrapper--no-image',
              )}
            >
              {collection?.image?.sourceUrl && (
                <div className='ShopCatalogBanner__image'>
                  <img src={collection?.image?.sourceUrl} alt={collection?.name} />
                </div>
              )}
              <div className='ShopCatalogBanner__content'>
                {collection?.parent?.node?.name && <h4>{collection?.parent.node.name}</h4>}
                <h1>{collection?.name}</h1>
                <p>{collection?.description}</p>
              </div>
            </div>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
