import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { useLanguageContext } from '../../../lib/context/LanguageContext/LanguageContext';

type NavLinkRenderProps = {
    isActive: boolean;
    isPending: boolean;
    isTransitioning: boolean;
};

export const ThemeLink = (
  {activeClassName, className, ...props}: NavLinkProps & React.RefAttributes<HTMLAnchorElement> & {activeClassName?: string}
) => {
  const langCtx = useLanguageContext();
  const prefix = langCtx.getLangPrefix();

  const classNameFn: ((props: NavLinkRenderProps) => string | undefined) = (props) => {
    if (props.isActive) {
      return `${className} ${activeClassName}`;
    }

    return className && typeof className === 'string' ? className : '';
  };

  return (
    <NavLink {...props} to={prefix + props.to} className={classNameFn}>
      {props.children}
    </NavLink>
  );
};
