import React from 'react';
import { useLoginFormik } from '../../lib/formik/useLoginFormik';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { LoginForm } from './LoginForm';

import './Login.scss';

export interface LoginFields {
  email: string;
  password: string;
}

export const Login = () => {
  const authCtx = useAuthContext();

  const formik = useLoginFormik({
    onSubmit: async (values) => authCtx.login(values.email, values.password),
  });

  return (
    <div className='Login'>
      <div className='Login__wrapper'>
        <h4>Login</h4>
        <LoginForm formik={formik} isLoading={authCtx.isLoggingIn} />
      </div>
    </div>
  );
};
