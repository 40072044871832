import React from 'react';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { useQuery } from '@apollo/client';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import { HomeOtherCollectionsLoader } from './HomeOtherCollectionsLoader';
import { HomeOtherCollectionsResponse, getHomeOtherCollections } from '../../../queries/home';
import { ThemeLink } from '../../shared/ThemeLink/ThemeLink';
import './HomeOtherCollections.scss';

export const HomeOtherCollections = () => {
  const { loading, data, error } = useQuery<HomeOtherCollectionsResponse>(getHomeOtherCollections);

  const collections = data?.generalOption.homeSettings.otherCollections.collections;

  return (
    <div className='HomeOtherCollections'>
      <div className='HomeOtherCollections__header'>
        <h3>Latest Collections</h3>
      </div>
      <div className='HomeOtherCollections__list'>
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <BulkLoader length={3}>
              <HomeOtherCollectionsLoader />
            </BulkLoader>
          }
        >
          <ApolloErrorGuard error={error}>
            {collections?.map((collection, index) => {
              return (
                <ThemeLink
                  to={collection.link}
                  className='HomeOtherCollections__collection'
                  key={index}
                >
                  <div className='HomeOtherCollections__collection-img'>
                    <img src={collection.image.node.sourceUrl} alt={collection.title} />
                  </div>
                  <div>
                    <h6>{collection.title}</h6>
                    <span>See more</span>
                  </div>
                </ThemeLink>
              );
            })}
          </ApolloErrorGuard>
        </HandleLoadingState>
      </div>
    </div>
  );
};
