import React from 'react';
import cn from 'classnames';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { BlockButton } from '../../components/shared/BlockButton/BlockButton';
import { actions, formInputs, messages } from '../../lib/translation/strings';
import { APPOINTMENT_CATEGORIES, ONLINE_APPOINTMENT_TYPES, PHONE_PREFIXES } from '../../constants';
import { Alert } from '../../components/Notifications/Alert';

interface Props {
  loading: boolean;
  formik: FormikValues;
  success?: boolean;
  error?: string;
}

export const BookAppointmentForm = ({ formik, loading, success, error }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='BookAppointmentForm'>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs='12' sm='12'>
            <FormGroup className='form-group'>
              <Label for='full_name'>{t(formInputs.fullName)}</Label>
              <Input
                id='full_name'
                type='text'
                name='full_name'
                className='form-control'
                value={formik.values.full_name}
                onChange={formik.handleChange}
                invalid={!!formik.errors.full_name && formik.touched.full_name}
              />
              <FormFeedback>{formik.errors.full_name}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='4'>
            <FormGroup className='form-group'>
              <Label for='phone_code'>{t(formInputs.phonePrefix)}</Label>
              <Input
                id='phone_code'
                type='select'
                className='form-control-custom form-control'
                invalid={!!formik.errors.phone_code && formik.touched.phone_code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('phone_code', e.target.value);
                  formik.setFieldTouched('phone_code');
                }}
              >
                <option disabled selected></option>
                {PHONE_PREFIXES.map(({ label, value }, index) => (
                  <option key={`${value}-phone-code-${index}`} value={value}>
                    {label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formik.errors.phone_code}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='8'>
            <FormGroup className='form-group'>
              <Label for='phone'>{t(formInputs.phoneNumber)}</Label>
              <Input
                id='phone'
                type='text'
                name='phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.phone && formik.touched.phone}
              />
              <FormFeedback>{formik.errors.phone}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='12'>
            <FormGroup className='form-group'>
              <Label for='email'>{t(formInputs.email)}</Label>
              <Input
                id='email'
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.email && formik.touched.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='category'>{t(formInputs.category)}</Label>
              <Input
                id='category'
                name='category'
                type='select'
                className='form-control-custom form-control'
                invalid={!!formik.errors.category && formik.touched.category}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('category', e.target.value);
                  formik.setFieldTouched('category');
                }}
              >
                <option disabled selected></option>
                {APPOINTMENT_CATEGORIES.map(({ label, value }) => (
                  <option key={`${value}-category`} value={value}>
                    {label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formik.errors.category}</FormFeedback>
            </FormGroup>
          </Col>
          {/* <Col xs='6' sm='3'>
            <FormGroup className='form-group form-radio'>
              <Input
                id='appointment_type-1'
                type='radio'
                name='appointment_type'
                className='form-control-custom'
                value='online'
                onChange={formik.handleChange}
              />
              <Label for='appointment_type-1'>Online</Label>
            </FormGroup>
          </Col> */}
          <Col xs='6' sm='3'>
            <FormGroup className='form-group form-radio'>
              <Input
                id='appointment_type-2'
                type='radio'
                name='appointment_type'
                className='form-control-custom'
                value='in-boutique'
                onChange={formik.handleChange}
              />
              <Label for='appointment_type-2'>In-Boutique</Label>
            </FormGroup>
          </Col>
          <Col xs='12' style={{ marginTop: '-20px', marginBottom: '30px' }}>
            <div
              className={cn(
                !!formik.errors.appointment_type && formik.touched.appointment_type && 'is-invalid',
              )}
            />
            <FormFeedback>{formik.errors.appointment_type}</FormFeedback>
          </Col>
          {formik.values.appointment_type === 'online' && (
            <Col xs='12'>
              <FormGroup className='form-group'>
                <Label for='online_appointment_type'>{t(formInputs.onlineAppointmentType)}</Label>
                <Input
                  id='online_appointment_type'
                  name='online_appointment_type'
                  type='select'
                  className='form-control-custom form-control'
                  invalid={
                    !!formik.errors.online_appointment_type &&
                    formik.touched.online_appointment_type
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('online_appointment_type', e.target.value);
                    formik.setFieldTouched('online_appointment_type');
                  }}
                >
                  <option disabled selected></option>
                  {ONLINE_APPOINTMENT_TYPES.map(({ label, value }) => (
                    <option key={`${value}-online-appointment-type`} value={value}>
                      {label}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formik.errors.online_appointment_type}</FormFeedback>
              </FormGroup>
            </Col>
          )}
          <Col xs='12'>
            {success && <Alert message={t(messages.contactSuccess)} type='success' />}
            {error && <Alert message={error.replace('GraphQL error: ', '')} type='danger' />}
            <FormGroup>
              <BlockButton
                type='submit'
                disabled={loading}
                className=' Contact__btn_submit w-100-mobile'
              >
                {loading ? t(actions.sending) : t(actions.sendInquiry)}
              </BlockButton>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
