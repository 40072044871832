import React from 'react';
import { Container } from 'reactstrap';
import { Icon } from '../../components/shared/Icon/Icon';

import './CheckoutConfirmation.scss';

export const CheckoutConfirmation = () => {
  return (
    <div className='CheckoutConfirmation'>
      <Container>
        <div className='CheckoutConfirmation__head'>
          <div className='icon'>
            <Icon icon='shopping-bag' />
          </div>

          <h1 className='success-title'>Thank you for your order!</h1>
          <h3 className='success-subtitle'>Your order has been placed</h3>

          <div className='content'>
            <p>
              We are getting started on your order right away, your order confirmation has been sent
              to your email.
            </p>
            <p>Order tracking information will be sent to your email when your order ships.</p>
          </div>
        </div>
      </Container>
    </div>
  );
};
