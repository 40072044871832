import { useFormik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { formValidation } from '../translation/strings';

export interface CheckoutFields {
  billing: {
    firstName: string;
    lastName: string;
    address1: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
    phone: string;
  };
  shipping: {
    firstName?: string;
    lastName?: string;
    address1?: string;
    city?: string;
    state: string;
    postcode: string;
    country: string;
  };

  createAccount?: boolean;
  email: string;
  password?: string;
  passwordConfirm?: string;

  billingSameAsShipping: boolean;
  comment: string;
  termsAndConditions: boolean;
  paymentMethod: string;
}

interface UseCheckoutFormOptions {
  enableReinitialize?: boolean;
  initialValues: CheckoutFields;
  onSubmit: (values: CheckoutFields, formikHelpers: FormikHelpers<CheckoutFields>) => Promise<any>;
}

export const useCheckoutFormik = (options: UseCheckoutFormOptions) => {
  const { t } = useTranslation();

  const CheckoutSchema = Yup.object().shape(
    {
      billing: Yup.object().shape({
        firstName: Yup.string().required(t(formValidation.firstName)),
        lastName: Yup.string().required(t(formValidation.lastName)),
        address1: Yup.string().required(t(formValidation.address)),
        city: Yup.string().required(t(formValidation.city)),
        postcode: Yup.string().required(t(formValidation.zipCode)),
        country: Yup.string().required(t(formValidation.country)),
      }),
      shipping: Yup.object().when('billingSameAsShipping', (billingSameAsShipping, schema) => {
        if (!billingSameAsShipping) {
          return schema.shape({
            firstName: Yup.string().required(t(formValidation.firstName)),
            lastName: Yup.string().required(t(formValidation.lastName)),
            city: Yup.string().required(t(formValidation.city)),
            postcode: Yup.string().required(t(formValidation.zipCode)),
            country: Yup.string().required(t(formValidation.country)),
            address1: Yup.string().required(t(formValidation.address)),
          });
        }
        return schema;
      }),
      billingSameAsShipping: Yup.boolean(),
      password: Yup.string().when('createAccount', (createAccount, schema) => {
        if (!createAccount) {
          return schema.required(t(formValidation.password));
        }
        return schema;
      }),
      passwordConfirm: Yup.string()
        .when('createAccount', (createAccount, schema) => {
          if (!createAccount) {
            return schema.required(t(formValidation.password));
          }
          return schema;
        })
        .oneOf([Yup.ref('password')], t(formValidation.passwordNotMatch)),
      createAccount: Yup.boolean(),
      comment: Yup.string(),
      paymentMethod: Yup.string().required(t(formValidation.paymentMethod)),
      termsAndConditions: Yup.bool().oneOf([true], t(formValidation.termsAndConditions)),
    },
    [
      ['billingSameAsShipping', 'shipping'],
      ['createAccount', 'password'],
    ],
  );

  return useFormik({
    enableReinitialize: options.enableReinitialize,
    initialValues: {
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CheckoutSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type CheckoutFormik = ReturnType<typeof useCheckoutFormik>;
