/* eslint-disable camelcase */
import React from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PAGE_BY_SLUG, GetPageBySlugResponse } from '../../queries/generalQueries';
import { formValidation } from '../../lib/translation/strings';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { BookAppointmentForm } from './BookAppointmentForm';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { ThemeContainer } from '../../components/shared/ThemeContainer/ThemeContainer';
import { logError } from '../../lib/helpers/logError';
import { PageBanner } from '../../components/shared/PageBanner/PageBanner';
import {
  APPOINTMENT_FORM,
  AppointmentFormInput,
  AppointmentFormResponse,
} from '../../queries/mutations/appointmentMutation';
import { BookAppointmentPlaceholder } from './BookAppointmentLoader';
import './BookAppointment.scss';

export const BookAppointment = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<GetPageBySlugResponse>(GET_PAGE_BY_SLUG, {
    variables: {
      slug: 'book-your-appointment',
    },
  });
  const [contactFormSubmission, contactForm] = useMutation<
    AppointmentFormResponse,
    AppointmentFormInput
  >(APPOINTMENT_FORM);

  const formik = useFormik<AppointmentFormInput>({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      phone_code: '',
      category: '',
      appointment_type: '',
      online_appointment_type: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required(t(formValidation.firstName)),
      email: Yup.string().email().required(t(formValidation.email)),
      phone: Yup.string().required(t(formValidation.phone)),
      phone_code: Yup.string().required(t(formValidation.phonePrefix)),
      category: Yup.string().required(t(formValidation.category)),
      appointment_type: Yup.string().required(t(formValidation.appointmentType)),
      online_appointment_type: Yup.string().when('appointment_type', (appointmentType, schema) => {
        if (appointmentType[0] === 'online') {
          return schema.required(t(formValidation.onlineAppointmentType));
        }
        return schema;
      }),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await contactFormSubmission({
          variables: values,
        });

        formikHelpers.resetForm();
      } catch (error) {
        logError(error);
      }
    },
  });

  const page = data?.pageBy;
  const image = page?.featuredImage?.node?.sourceUrl;
  return (
    <div className='BookAppointment'>
      <div className={cn('BookAppointment', image && 'BookAppointment--with-banner')}>
        {image && <PageBanner imageUrl={image} title={page?.title ?? ''} />}
        <ThemeContainer>
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              <BulkLoader length={1}>
                <BookAppointmentPlaceholder />
              </BulkLoader>
            }
          >
            <ApolloErrorGuard error={error}>
              <>
                {!image && <HeadingTitle title={page?.title ?? ''} />}
                <div className='DefaultPage__content'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page?.content || '',
                    }}
                  />
                </div>
                <BookAppointmentForm
                  formik={formik}
                  loading={loading || contactForm.loading}
                  success={!!contactForm?.data}
                  error={contactForm?.error?.message}
                />
              </>
            </ApolloErrorGuard>
          </HandleLoadingState>
        </ThemeContainer>
      </div>
    </div>
  );
};
