import { CartProduct } from '../../../../types/cartTypes';

export const canAddToCart = (
  item: CartProduct | undefined,
  quantity: number,
  variationId: string | undefined
) => {
  if (!item) {
    return false;
  }

  if (
    item.product.node.type === 'SIMPLE' &&
    item.product.node.stockQuantity &&
    item.product.node.stockQuantity < quantity + item.quantity
  ) {
    return false;
  }

  if (
    item.variation &&
    item.variation.node.id === variationId &&
    item.variation.node.stockQuantity &&
    item.variation.node.stockQuantity < quantity + item.quantity
  ) {
    return false;
  }

  return true;
};
