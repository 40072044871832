import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../shared/Icon/Icon';
import { actions } from '../../../../lib/translation/strings';
import { useShopContext } from '../../../../lib/context/ShopContext/ShopContext';

import './ShopFilterButton.scss';
import { useRouter } from '../../../../lib/hooks/useRouter';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const ShopFilterButton = (props: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const count = router.location.search.includes('?') && router.location.search.split('&').length;
  const {
    sidebar: { openSidebar },
  } = useShopContext();

  const handleClick = () => {
    openSidebar();
  };

  return (
    <div className={cs('ShopFilterButton', props.className)} onClick={handleClick}>
      {count && <span className='ShopFilterButton__count'>{count}</span>}
      <Icon icon='plus' />
      <p>{t(actions.filter)}</p>
    </div>
  );
};
