import gql from 'graphql-tag';

export interface ProductArchiveItem {
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  regularPrice: string;
  salePrice: string;
  image: {
    srcSet: string;
    mediaItemUrl: string;
  };
}

export interface CustomAttributeOption {
  id: number;
  slug: string;
  name: string;
  image: string;
}

export interface ProductSingleAttributeProps {
  name: string;
  options: string[];
  id: string;
  scope: string;
  visible: boolean;
  customAttributesOptions: CustomAttributeOption[];
  enabled_options?: (string | null)[];
}
export interface ProductSingleVariationProps {
  id: string;
  databaseId: number;
  regularPrice: string;
  salePrice: string;
  sku: string;
  onSale: boolean;
  stockQuantity: number;
  image?: {
    slug?: string;
  };
  attributes: {
    nodes: {
      name: string;
      value: string;
    }[];
  };
}

export interface ProductOptionTab {
  title: string;
  description: string;
}

export interface ProductOptions {
  howToUse?: ProductOptionTab[];
  ingridients?: ProductOptionTab;
  benefits?: ProductOptionTab;
  skintype?: ProductOptionTab;
  qa?: ProductOptionTab;
}

export interface ProductSingleProps {
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  description: string;
  onSale: boolean;
  sku: string;
  type: string;
  regularPrice: string;
  salePrice: string;
  stockQuantity: number;
  image: {
    slug?: string;
    sourceUrl: string;
  };
  galleryImages: {
    nodes: {
      slug?: string;
      sourceUrl: string;
    }[];
  };
  productCategories: {
    nodes: {
      slug: string;
    }[];
  };
  productOptions: ProductOptions;
  attributes: {
    nodes: ProductSingleAttributeProps[];
  };
  variations: {
    nodes: ProductSingleVariationProps[];
  };
}
export const GET_ARCHIVE_PRODUCTS = gql`
  query archiveQuery($limit: Int, $after: String) {
    products(first: $limit, after: $after) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: MEDIUM)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
  query getRelatedProducts($exclude: [Int], $category: String) {
    products(where: { exclude: $exclude, category: $category }, first: 4) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
    }
  }
`;

export const GET_SINGLE_PRODUCTS = gql`
  query singleProduct($slugIn: [String]) {
    products(where: { slugIn: $slugIn }) {
      nodes {
        id
        slug
        databaseId
        name
        description
        onSale
        sku
        type
        image {
          id
          sourceUrl(size: _2048X2048)
        }
        ... on SimpleProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          galleryImages {
            nodes {
              id
              sourceUrl(size: _2048X2048)
            }
          }
          productCategories {
            nodes {
              id
              slug
            }
          }
          attributes {
            nodes {
              name
              options
              id
              scope
              visible
              customAttributesOptions {
                id
                slug
                name
                image
              }
            }
          }
        }
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          galleryImages {
            nodes {
              id
              sourceUrl(size: _2048X2048)
            }
          }
          productCategories {
            nodes {
              id
              slug
            }
          }
          attributes {
            nodes {
              name
              options
              id
              scope
              visible
              customAttributesOptions {
                id
                slug
                name
                image
              }
            }
          }
          variations(first: 1000) {
            nodes {
              id
              databaseId
              regularPrice(format: FORMATTED)
              salePrice(format: FORMATTED)
              sku
              onSale
              stockQuantity
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_WISHLIST = gql`
  query getUserWishlist($products: [Int]) {
    products(first: 100, where: { include: $products }) {
      nodes {
        id
        slug
        name
        databaseId
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
    }
  }
`;

export const GET_SEARCH_QUERY = gql`
  query getSearchQuery($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: MEDIUM)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export type DeliveryAndReturnsTextResponse = {
  generalOption: {
    generalOptions: {
      deliveryReturns: string;
      sizeGuide: string;
    };
  };
};

export const GET_DELIVERY_AND_RETURNS_TEXT = gql`
  query getDeliveryAndReturnsText {
    generalOption {
      generalOptions {
        deliveryReturns
        sizeGuide
      }
    }
  }
`;
