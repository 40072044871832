import React from 'react';
import { ProfileOrders } from '../../components/ProfilePages/ProfileOrders/ProfileOrders';
import { ProfileAddress } from '../../components/ProfilePages/ProfileAddress/ProfileAddress';
import { ProfileAccount } from '../../components/ProfilePages/ProfileAccount/ProfileAccount';
import { ProfilePassword } from '../../components/ProfilePages/ProfilePassword/ProfilePassword';
import { ProfileOrdersSingle } from '../../components/ProfilePages/ProfileOrders/ProfileOrdersSingle';
import { useRouter } from '../../lib/hooks/useRouter';

export const ProfileContent = () => {
  const router = useRouter();

  const ChildComponent = () => {
    if (router.pathname === '/my-profile/security') {
      return <ProfilePassword />;
    } else if (router.pathname === '/my-profile/orders') {
      return <ProfileOrders />;
    } else if (router.pathname.includes('/my-profile/orders/')) {
      return <ProfileOrdersSingle />;
    } else if (router.pathname === '/my-profile/address') {
      return <ProfileAddress />;
    } else {
      return <ProfileAccount />;
    }
  };

  return (
    <div className='ProfileContent flex-fill'>
      <ChildComponent />
    </div>
  );
};
