import { useQuery } from '@apollo/client';
import { GET_CATEGORIES_MENU, GetSubCategoriesBySlugResponse } from '../../queries/menu';

export const useFetchMenu = () => {
  const { data, loading, error } = useQuery<GetSubCategoriesBySlugResponse>(GET_CATEGORIES_MENU);

  return {
    categories: data?.productCategories.nodes || [],
    loading,
    error,
  };
};
